import { useEffect, useState } from "react";
import StyleComponent from "./styleComponent";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ISessionProps } from "./IProgress";
import Grid from "@mui/material/Grid";
import {
  Feedback,
  sendFeedback,
  updateProgressState,
} from "../../services/api";
import HomeIcon from "@mui/icons-material/Home";
import { Box, Tooltip, Button } from "@mui/material";
import Progress from "./Progress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Typography,
  Select,
  Dialog,
  ToggleButtonGroup,
  TextField,
} from "@appsflyer/fe-ui-core";
import ToggleButton from "@mui/material/ToggleButton";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import "./Layout.css";
import LaunchIcon from "@mui/icons-material/Launch";
import { amplitudeTrack, amplitudeTrack_view } from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";

interface LayoutProps {
  sessionTime: number;
}

const Layout = (props: LayoutProps) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const setLangOnLoad = (lang: { value: string; label: string }) => {
    setSelectedLang(lang);
    i18n.changeLanguage(lang.value);
    if (location?.state) location.state.lang = lang.value;
    localStorage.setItem(`language`, JSON.stringify(lang));
  };

  useEffect(() => {
    const lsLang = localStorage.getItem("language");
    if (lsLang) {
      const parsedLang = JSON.parse(lsLang);
      setSelectedLang(parsedLang);
      i18n.changeLanguage(parsedLang?.value);
      if (location?.state) location.state.lang = parsedLang?.value;
    }

    // check if a lang parameter is received
    if (location.search && location.search.indexOf("lang=") > -1) {
      const lang = location.search.substring(
        location.search.indexOf("lang=") + 5,
        location.search.indexOf("lang=") + 7
      );
      switch (lang) {
        case "en":
          setLangOnLoad({
            value: "en",
            label: "English",
          });
          break;
        case "fr":
          setLangOnLoad({
            value: "fr",
            label: "Français",
          });
          break;
        case "es":
          setLangOnLoad({
            value: "es",
            label: "Español",
          });
          break;
        case "ru":
          setLangOnLoad({
            value: "ru",
            label: "Русский",
          });
          break;
        case "pt":
          setLangOnLoad({
            value: "pt",
            label: "Portuguese",
          });
          break;
        case "zh":
          setLangOnLoad({
            value: "zh",
            label: "简体中文",
          });
          break;
        case "ko":
          setLangOnLoad({
            value: "ko",
            label: "한국어",
          });
          break;
        case "ja":
          setLangOnLoad({
            value: "ja",
            label: "日本語",
          });
          break;

        default:
          break;
      }
    }

    if (!location.state) {
      navigate("/" + location.search);
    }
  }, []);

  useEffect(() => {
    // send dj_page_view event to amplitude on page change
    const pagename =
      location.pathname.length > 2
        ? location.pathname.substring(1)
        : "homepage";

    if (location.state) {
      const state: ISessionProps = location.state;
      amplitudeTrack_view(state);
    } else {
      amplitudeTrack("dj_page_view", {
        title: pagename,
      });
    }

    if (location.pathname === "/finish") {
      console.log("finish gil!");
      setIsOpenSuggestion(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.state) {
      const state: ISessionProps = location.state as ISessionProps;
      // console.log(state); // TODO: remove on implementions
      state.sessionDuration = props.sessionTime;
      state.updateTime = new Date();

      // update times for each step
      if (state.progress.length) {
        // if there is no start time - add a start time
        if (!state.progress[state.progress.length - 1].startTime) {
          state.progress[state.progress.length - 1].startTime = new Date();
        }

        // update the last step
        state.progress[state.progress.length - 1].updateTime = new Date();

        if (
          location.pathname.split("/").pop() === "finish" ||
          location.pathname === "finish"
        ) {
          const msDiff =
            state.progress[state.progress.length - 1].updateTime?.getTime() -
            state.progress[state.progress.length - 1].startTime?.getTime();
          state.progress[state.progress.length - 1].duration = msDiff / 1000;
        }

        // "finish" the previous step
        if (state.progress.length > 1) {
          state.progress[state.progress.length - 2].updateTime = new Date();
          const msDiff =
            state.progress[state.progress.length - 2].updateTime?.getTime() -
            state.progress[state.progress.length - 2].startTime?.getTime();
          state.progress[state.progress.length - 2].duration = msDiff / 1000;
        }
      }
      updateProgressState(state);
    }
  }, [location.state]);

  const [isOpenSuggestion, setIsOpenSuggestion] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenThanks, setIsOpenThanks] = useState(false);
  const [selectLang, setSelectedLang] = useState(null);
  const [usefulValue, setUsefulValue] = useState("");
  const [easyValue, setEasyValue] = useState("");
  const [feedbackValue, setFeedbackValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [isFollowUp, setIsFollowUp] = useState(true);

  const languages = [
    {
      value: "en",
      label: "English",
    },
    {
      value: "fr",
      label: "Français",
    },
    {
      value: "es",
      label: "Español",
    },
    {
      value: "ru",
      label: "Русский",
    },
    {
      value: "pt",
      label: "Portuguese",
    },
    {
      value: "zh",
      label: "简体中文",
    },
    {
      value: "ko",
      label: "한국어",
    },
    {
      value: "ja",
      label: "日本語",
    },
  ];

  const changeLanguageHandler = (lang) => {
    setSelectedLang(lang);
    i18n.changeLanguage(lang.value);
    localStorage.setItem(`language`, JSON.stringify(lang));
    location.state.lang = lang.value;
  };

  const emailRegex = new RegExp( //eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const renderButtonLabel = ({ className, ref, isTextOverflow }) => {
    const textElement = (
      <Box display={"flex"} alignItems={"center"}>
        {/* <LanguageIcon style={{ color: "#1976d2" }} /> */}
        <Box ref={ref} className={className} ml={1}>
          {selectLang.label}
        </Box>
      </Box>
    );
    if (isTextOverflow) {
      return <Tooltip title={selectLang.label}>{textElement}</Tooltip>;
    } else {
      return textElement;
    }
  };

  const onClickFeedback = () => {
    const feedback: Feedback = {
      feedbackValue,
      usefulValue,
      easyValue,
      emailValue,
    };
    sendFeedback(feedback, location.state);
    setUsefulValue("");
    setEasyValue("");
    setFeedbackValue("");
    setEmailValue("");
    setIsOpen(false);
    setIsOpenThanks(true);
  };

  return (
    <div>
      <StyleComponent>
        <Grid container>
          <Grid
            item
            mx={"auto"}
            display={"flex"}
            flexDirection={"column"}
            md={8}
            xs={12}
          >
            <Box minHeight={70} display={"flex"} alignItems={"center"}>
              <Box display={"flex"} flex={1} gap={2}>
                <Tooltip title="Homepage" arrow>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    style={{ height: 50 }}
                    onClick={() => navigate("/")}
                  >
                    <HomeIcon color="primary" />
                  </Button>
                </Tooltip>
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <a href="https://www.appsflyer.com/" target="_blank">
                  <img
                    style={{
                      width: "120px",
                      display: "flex",
                      alignSelf: "center",
                    }}
                    src={process.env.PUBLIC_URL + "/images/afLogo.svg"}
                  />
                </a>
              </Box>
              <Box display={"flex"} flex={1} justifyContent={"flex-end"}>
                <Box display={"flex"} gap={1}>
                  <Button
                    variant="text"
                    color="primary"
                    style={{ height: 50 }}
                    size="medium"
                  >
                    <AmplitudeLink
                      color="primary"
                      href={"https://dev.appsflyer.com/hc"}
                      target="_blank"
                    >
                      <Box display={"flex"} gap={0.5}>
                        Docs
                        <LaunchIcon color="primary" />
                      </Box>
                    </AmplitudeLink>
                  </Button>
                  {/* Feedback button */}
                  {location?.state && location?.state.appID && (
                    <>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        onClick={() => setIsOpen(true)}
                      >
                        Feedback
                      </Button>
                      <Dialog
                        size={"m"}
                        DialogHeaderProps={{
                          title: "Feedback Form",
                          description: (
                            <>
                              Thank you for taking the time to share your
                              thoughts on AppsFlyer DevJourney integration
                              wizard.
                              <br />
                              Your feedback will help us continue to improve the
                              customer experience.
                            </>
                          ),
                        }}
                        footer={
                          <>
                            <Button
                              onClick={() => setIsOpen(false)}
                              color="primary"
                              variant="text"
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={onClickFeedback}
                              color="primary"
                              variant="contained"
                              disabled={
                                (isFollowUp &&
                                  (!emailValue ||
                                    !emailRegex.test(emailValue))) ||
                                (usefulValue == "" &&
                                  easyValue == "" &&
                                  feedbackValue == "")
                              }
                            >
                              Send
                            </Button>
                          </>
                        }
                        handleClose={() => setIsOpen(false)}
                        isOpen={isOpen}
                      >
                        <>
                          <Box mb={4}>
                            <Typography>Dev Journey is useful to me</Typography>
                            <ToggleButtonGroup
                              size="large"
                              value={usefulValue}
                              onChange={(event, value) => setUsefulValue(value)}
                              exclusive
                              sx={{
                                border: "none",
                                height: 70,
                                mt: 1,
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              {[
                                "Strongly disagree",
                                "Disagree",
                                "Neutral",
                                "Agree",
                                "Strongly agree",
                              ].map((val, index) => {
                                return (
                                  <ToggleButton
                                    value={index + 1 + ""}
                                    key={index + 1}
                                    className={"toggleBtnCustom"}
                                  >
                                    <Box
                                      display={"flex"}
                                      flexDirection={"column"}
                                      alignItems={"center"}
                                    >
                                      <Typography className="agreementTile">
                                        {index + 1}
                                      </Typography>
                                      <Typography
                                        style={{ fontSize: "12px" }}
                                        width={57}
                                      >
                                        {val}
                                      </Typography>
                                    </Box>
                                  </ToggleButton>
                                );
                              })}
                            </ToggleButtonGroup>
                          </Box>
                          <Box my={4}>
                            <Typography>Dev Journey is easy to use</Typography>
                            <ToggleButtonGroup
                              size="small"
                              value={easyValue}
                              onChange={(event, value) => setEasyValue(value)}
                              exclusive
                              sx={{
                                border: "none",
                                height: 70,
                                mt: 1,
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              {[
                                "Strongly disagree",
                                "Disagree",
                                "Neutral",
                                "Agree",
                                "Strongly agree",
                              ].map((val, index) => {
                                return (
                                  <ToggleButton
                                    value={index + 1 + ""}
                                    key={index + 1}
                                    className={"toggleBtnCustom"}
                                  >
                                    <Box
                                      display={"flex"}
                                      flexDirection={"column"}
                                      alignItems={"center"}
                                    >
                                      <Typography className="agreementTile">
                                        {index + 1}
                                      </Typography>
                                      <Typography
                                        style={{ fontSize: "12px" }}
                                        width={57}
                                      >
                                        {val}
                                      </Typography>
                                    </Box>
                                  </ToggleButton>
                                );
                              })}
                            </ToggleButtonGroup>
                          </Box>
                          <Box my={4}>
                            <TextField
                              variant="outlined"
                              size="xl"
                              placeholder="Feel free to feedback on anything you have in mind, from improvements and suggestions to difficulties and bugs"
                              value={feedbackValue}
                              inputProps={{ className: "amp-unmask" }}
                              multiline={true}
                              maxRows={4}
                              label="What's on your mind?"
                              onChange={(event) =>
                                setFeedbackValue(event.target.value)
                              }
                            />
                          </Box>
                          <Box mt={4}>
                            <Typography>
                              Can we follow up with you about your feedback?
                            </Typography>
                            <Box display={"flex"} gap={2} mt={1}>
                              <Button
                                variant={isFollowUp ? "contained" : "outlined"}
                                color={"primary"}
                                size="medium"
                                onClick={() => setIsFollowUp(true)}
                              >
                                Yes
                              </Button>
                              <Button
                                variant={!isFollowUp ? "contained" : "outlined"}
                                color={"primary"}
                                size="medium"
                                onClick={() => setIsFollowUp(false)}
                              >
                                No
                              </Button>
                            </Box>
                          </Box>
                          {isFollowUp && (
                            <Box mt={4}>
                              <TextField
                                variant="outlined"
                                size="m"
                                required
                                type="email"
                                label="Email address for contact*"
                                placeholder="your.email@example.com"
                                value={emailValue}
                                inputProps={{ className: "amp-unmask" }}
                                error={
                                  emailValue != "" &&
                                  !emailRegex.test(emailValue)
                                }
                                helperText="Please enter a valid email"
                                onChange={(event) =>
                                  setEmailValue(event.target.value)
                                }
                              />
                            </Box>
                          )}
                        </>
                      </Dialog>
                      <Dialog
                        size={"s"}
                        DialogHeaderProps={{
                          title: " ",
                        }}
                        footer={
                          <>
                            <Button
                              onClick={() => setIsOpenThanks(false)}
                              color="primary"
                              variant="contained"
                            >
                              Done
                            </Button>
                          </>
                        }
                        handleClose={() => setIsOpenThanks(false)}
                        isOpen={isOpenThanks}
                      >
                        <>
                          <Box
                            mb={4}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                          >
                            <img
                              style={{
                                width: "220px",
                              }}
                              src={process.env.PUBLIC_URL + "/images/Done.svg"}
                            />
                            <Typography variant="h1">Thank you!</Typography>
                          </Box>
                        </>
                      </Dialog>
                      <Dialog
                        size={"s"}
                        DialogHeaderProps={{
                          title: " ",
                        }}
                        footer={
                          <>
                            <Button
                              onClick={() => setIsOpenSuggestion(false)}
                              color="primary"
                              variant="outlined"
                            >
                              Not this time
                            </Button>
                            <Button
                              onClick={() => {
                                setIsOpenSuggestion(false);
                                setIsOpen(true);
                              }}
                              color="primary"
                              variant="contained"
                            >
                              Sure!
                            </Button>
                          </>
                        }
                        handleClose={() => setIsOpenSuggestion(false)}
                        isOpen={isOpenSuggestion}
                      >
                        <>
                          <Box
                            mb={4}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                          >
                            <Typography variant="h1">
                              We'd appreciate your feedback!
                            </Typography>
                            <br />
                            <br />
                            <img
                              style={{
                                width: "220px",
                              }}
                              src={
                                process.env.PUBLIC_URL + "/images/Feedback.svg"
                              }
                            />
                          </Box>
                        </>
                      </Dialog>
                    </>
                  )}

                  {/* Language selector */}
                  <Box display={"flex"} alignItems={"center"} ml={1}>
                    <LanguageIcon style={{ color: "primary" }} />
                    <Select
                      options={languages}
                      value={selectLang}
                      onChange={(v) => changeLanguageHandler(v)}
                      size="small"
                      placeholder={"English"}
                      style={{
                        borderColor: "transparent",
                        backgroundColor: "transparent",
                        margin: "5px",
                        marginLeft: "-5px",
                        maxWidth: "150px",
                      }}
                      renderButtonLabel={
                        selectLang ? renderButtonLabel : undefined
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            {location.state?.progress && location.state.progress.length ? (
              <Box minHeight={50}>
                <Progress {...location.state}></Progress>
              </Box>
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            item
            mx={"auto"}
            display={"flex"}
            flexDirection={"column"}
            md={8}
            xs={12}
          >
            <Card>
              <CardContent>
                <Outlet />
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            mx={"auto"}
            display={"flex"}
            flexDirection={"column"}
            md={8}
            xs={12}
          >
            <Box mt={2} display={"flex"} justifyContent={"center"}>
              <Typography>
                © {new Date().getFullYear()} Copyright AppsFlyer Ltd. All rights
                reserved.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </StyleComponent>
    </div>
  );
};

export default Layout;
