import { ChangeEvent, useState } from "react";
import { Box, Container, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IProgress, ISessionProps } from "../../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Note } from "@appsflyer/fe-ui-core";
import transition from "../../main/components/transition";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { DJSimpleCodeBlock } from "../../main/components/codeblock";
import {
  amplitudeTrack_continue,
  amplitudeTrack_inputChange,
  InputType,
} from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";
import { migrationAllowList } from "../../services/api";

const DL_prepare3Android = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [devMode, setDevMode] = useState("dev");
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClick = async () => {
    const appId = location.state?.appID;

    // Check if app is in allowlist
    const res = await migrationAllowList(appId);

    const progressArr: IProgress[] = location.state?.progress.slice();
    progressArr.push({
      id: "dl_implementCode",
      text: "progress_implement_code",
      order: 3,
    });

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
    };
    amplitudeTrack_continue(location.state, { devMode });

    sessionStorage.setItem("previousPage", location.pathname); // Save the current URL before navigation

    // Navigate based on allowlist status
    if (res.allowed) {
      navigate(`/choosePathMigration`, { state: progressState });
    } else {
      navigate(`/deeplink/${location.state?.os}/implement`, {
        state: progressState,
      });
    }
  };

  const handleChangeDevMode = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    amplitudeTrack_inputChange(
      location.state,
      InputType.Radio,
      "devMode",
      event.target.value
    );
    setDevMode(value);
    setActiveStep(0);
  };

  const dev_steps = [
    {
      label: t("dl_android_prepare3_dev_step1_title"),
      component: (
        <>
          <Typography>
            {t("dl_android_prepare3_dev_step1_text") + " "}
            <AmplitudeLink
              href="https://developer.android.com/training/articles/keystore"
              target="_blank"
            >
              {t("dl_android_prepare3_dev_step1_text_b")}
            </AmplitudeLink>
          </Typography>
          <Typography>
            {t("dl_android_prepare3_dev_step1_text_c") + " "}
            <code>debug.keystore</code>
          </Typography>
          <ul>
            <li>
              <Typography>
                {t("dl_android_prepare3_dev_step1_li1") + " "}
                <code>C:\Users\USERNAME\.android\debug.keystore</code>
              </Typography>
            </li>
            <li>
              <Typography>
                {t("dl_android_prepare3_dev_step1_li2") + " "}
                <code>~/.android/debug.keystore</code>
              </Typography>
            </li>
          </ul>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_android_prepare3_dev_step2_title"),
      component: (
        <>
          <Typography>{t("dl_android_prepare3_dev_step2_text")}</Typography>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_android_prepare3_dev_step3_title"),
      component: (
        <>
          <Typography>{t("dl_android_prepare3_dev_step3_text")}</Typography>
          <DJSimpleCodeBlock
            codePrimary={`// keytool -list -v -keystore KEY_STORE_FILE
// For example, the default keystore file 
keytool -list -v -keystore ~/.android/debug.keystore`}
            language="shell"
            showLineNumbers={true}
          ></DJSimpleCodeBlock>
          <Box my={2}>
            <Note>{t("dl_android_prepare3_dev_step3_text_b")}</Note>
          </Box>
          <Box mt={3} mb={2}>
            <Typography>{t("dl_android_prepare3_dev_step3_text_c")}</Typography>
          </Box>
          <DJSimpleCodeBlock
            codePrimary={`Alias name: test
Creation date: Sep 27, 2017
Entry type: PrivateKeyEntry
Certificate chain length: 1
Certificate[1]:
Owner: CN=myname
Issuer: CN=myname
Serial number: 365ead6d
Valid from: Wed Sep 27 17:53:32 IDT 2017 until: Sun Sep 21 17:53:32 IDT 2042
Certificate fingerprints:
MD5: DB:71:C3:FC:1A:42:ED:06:AC:45:2B:6D:23:F9:F1:24
SHA1: AE:4F:5F:24:AC:F9:49:07:8D:56:54:F0:33:56:48:F7:FE:3C:E1:60
SHA256: A9:EA:2F:A7:F1:12:AC:02:31:C3:7A:90:7C:CA:4B:CF:C3:21:6E:A7:F0:0D:60:64:4F:4B:5B:2A:D3:E1:86:C9
Signature algorithm name: SHA256withRSA
Version: 3
Extensions:
#1: ObjectId: 2.5.29.14 Criticality=false
SubjectKeyIdentifier [
  KeyIdentifier [
    0000: 34 58 91 8C 02 7F 1A 0F  0D 3B 9F 65 66 D8 E8 65 
    0010: 74 42 2D 44                    
  ]
]`}
            language="text"
            showLineNumbers={true}
          ></DJSimpleCodeBlock>
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_android_prepare3_prod_step2_title"),
      component: (
        <>
          <Typography>{t("dl_android_prepare3_prod_step2_text")}</Typography>
          <br />
          <Note>{t("dl_android_prepare3_prod_step2_text_b")}</Note>
          <br />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  const prod_steps = [
    {
      label: t("dl_android_prepare3_prod_step1_title"),
      component: (
        <>
          <Typography>
            {t("dl_android_prepare3_prod_step1_text") + " "}
            <b>{t("dl_android_prepare3_prod_step1_text_b")}</b>
            {t("dl_android_prepare3_prod_step1_text_c")}
            <b>{t("dl_android_prepare3_prod_step1_text_d")}</b>{" "}
            {t("dl_android_prepare3_prod_step1_text_e")}
          </Typography>
          <br />
          <img
            style={{ width: "650px", display: "flex", alignSelf: "center" }}
            src={process.env.PUBLIC_URL + "/images/sha256prod.png"}
            alt={t("homepage_logoAlt")}
          />
          <br />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_android_prepare3_prod_step2_title"),
      component: (
        <>
          <Typography>{t("dl_android_prepare3_prod_step2_text")}</Typography>
          <br />
          <Note>{t("dl_android_prepare3_prod_step2_text_b")}</Note>
          <br />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box marginBottom={2}>
          <Typography sx={{ mb: 2 }} variant="h1">
            {t("dl_page7_android_prepareAppTitle")}
          </Typography>
          <Typography variant="h2">
            {t("dl_android_prepare3_dev_title")}
          </Typography>

          <Box mt={2}>
            <FormControl component="fieldset">
              <FormLabel htmlFor="" component="legend">
                {t("dl_android_prepare3_dev_subtitle")}
              </FormLabel>
              <RadioGroup
                row={true}
                defaultValue="dev"
                value={devMode}
                onChange={handleChangeDevMode}
              >
                <FormControlLabel
                  key="dev"
                  value="dev"
                  control={<Radio />}
                  label={t("dl_android_prepare3_dev_mode_dev")}
                />
                <FormControlLabel
                  key="prod"
                  value="prod"
                  control={<Radio />}
                  label={t("dl_android_prepare3_dev_mode_prod")}
                />
              </RadioGroup>
            </FormControl>
            <Stepper
              activeStep={activeStep}
              style={{ border: "none" }}
              orientation="vertical"
            >
              {(devMode === "dev" ? dev_steps : prod_steps).map(
                ({ label, component, shouldDisplayNavigationButtons }) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      {component}
                      {shouldDisplayNavigationButtons && (
                        <Box sx={{ mt: 2 }}>
                          {activeStep !== 0 && (
                            <Button
                              variant="text"
                              color="primary"
                              onClick={handleBack}
                              // sx={{ mt: 2, mr: 1 }}
                            >
                              {t("general_Back")}
                            </Button>
                          )}
                          {activeStep !==
                            (devMode === "dev" ? dev_steps : prod_steps)
                              .length -
                              1 && (
                            <Button
                              // sx={{ mt: 2 }}
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                            >
                              {activeStep ===
                              (devMode === "dev" ? dev_steps : prod_steps)
                                .length -
                                1
                                ? t("general_Finish")
                                : t("general_Next")}
                            </Button>
                          )}
                        </Box>
                      )}
                    </StepContent>
                  </Step>
                )
              )}
            </Stepper>
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => handleClick()}
        >
          {t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(DL_prepare3Android);
