import {
  Button,
  Box,
  Container,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Typography, TextField, Note, Tooltip } from "@appsflyer/fe-ui-core";
import transition from "../components/transition";
import { useState, useEffect } from "react";
import { translateCode } from "../../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import DiffViewer, { DiffMethod } from "react-diff-viewer";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import {
  IProgress,
  ISessionProps,
  SessionStatus,
} from "../components/IProgress";
import { t } from "i18next";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
import { EvgenEvent, EvgenEventsObject } from "../../inapp/IEvgen";
import { amplitudeTrack_migrateClick } from "../../services/utils";

const SdkMigration = () => {
  const [isConverted, setIsConverted] = useState(false);
  const [inputCode, setInputCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [newCode, setNewCode] = useState("");
  const [splitView, setSplitView] = useState(true); // Initial state
  const [migrateClick, setMigrateClick] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const migrationRouteType =
    sessionStorage.getItem("migrationRouteType") || "startsdk";

  const migrateCode = () => {
    setMigrateClick(migrateClick + 1);
    setIsConverted(true);
    setInputCode(inputCode);
    setIsLoading(true);

    let dk = "";
    const lsDevKey = localStorage.getItem("devKey");
    if (lsDevKey) {
      dk = JSON.parse(lsDevKey);
    }

    translateCode(inputCode, migrationRouteType)
      ?.then((res) => {
        console.log("res", res);
        if (!res.newCode) {
          console.warn("newCode is missing in response", res);
          return;
        }

        const getBody = res;
        console.log("res", getBody);

        setIsLoading(false);
        setNewCode(getBody.newCode.replace("YOUR_APPSFLYER_DEV_KEY", dk));
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
        console.error(err);
      });
    console.log(newCode);

    // Increment click count and track the event in Amplitude
    amplitudeTrack_migrateClick(location.state, migrateClick); // Amplitude tracking
    sendMigrateClicksToBackend(); // Send to backend
  };

  const sendMigrateClicksToBackend = async () => {
    const newState: ISessionProps = {
      ...location.state,
      migrateClick: migrateClick,
    };
    navigate(location.pathname, {
      replace: true,
      state: newState,
    });
  };

  useEffect(() => {
    console.log("Updated newCode:", newCode);
  }, [newCode]);

  const retry = () => {
    setIsConverted(false);
    setNewCode("");
    setInputCode("");
    sessionStorage.removeItem("sdkMigrationState"); // Clear saved state
  };

  const proceedToTest = () => {
    if (!isConverted || !newCode) return;

    // Save the current state in sessionStorage before navigation
    sessionStorage.setItem(
      "sdkMigrationState",
      JSON.stringify({ isConverted, inputCode, newCode })
    );

    const progressArr: IProgress[] = location.state?.progress.slice();

    progressArr.push({
      id: "inapp_testevents",
      text: "progress_getres",
      order: progressArr.length + 1,
      startTime: new Date(),
    });

    // Default progressState without evgenEventsObject
    let progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: location.state?.currentPage + 1,
      sessionStatus: SessionStatus.ImplementedNotTested,
    };

    // Add evgenEventsObject only if migrationRouteType is "inapp"
    if (migrationRouteType === "inapp") {
      const evgenEventsObject: EvgenEventsObject =
        generateEvgenEventsFromCode(newCode);
      progressState = {
        ...progressState,
        evgenEventsObject,
        isEvgenTesting: true,
      };
    }

    sessionStorage.setItem("evgenState", JSON.stringify(progressState));

    if (migrationRouteType == "deeplink") {
      navigate("/migration/deeplink/android/deeplinktype", {
        state: progressState,
      });
    } else {
      navigate(
        `/migration/${migrationRouteType}/${location.state.os}/testtype`,
        {
          state: progressState,
        }
      );
    }
  };

  const generateEvgenEventsFromCode = (code: string): EvgenEventsObject => {
    // console.log("DEBUG: Parsing newCode:\n", code);

    const eventRegex =
      /logEvent\s*\(\s*.*?,\s*(AFInAppEventType\.[A-Z_]+|".+?")\s*,\s*(\w+)\s*\)/g;

    const paramRegex =
      /(\w+)\.put\(\s*(AFInAppEventParameterName\.[A-Z_]+|".+?")\s*,\s*([^;]+?)\s*\);/g;

    const events: EvgenEvent[] = [];
    const eventValuesMap = new Map(); // Store eventValues variable names and parameters

    // Helper function to format identifiers
    const formatIdentifier = (identifier) => `af_${identifier.toLowerCase()}`;
    const formatName = (name) =>
      name
        .replace(/_/g, " ")
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());

    // Extract event parameters first
    let paramMatch;
    while ((paramMatch = paramRegex.exec(code)) !== null) {
      const eventValuesVar = paramMatch[1]; // e.g., eventValues1
      const parameterIdentifierRaw = paramMatch[2]
        .replace(/AFInAppEventParameterName\./, "")
        .replace(/"/g, "");
      const parameterValue = paramMatch[3].trim().replace(/"/g, ""); // Remove quotes

      const parameterIdentifier = formatIdentifier(parameterIdentifierRaw);
      const parameterName = formatName(parameterIdentifierRaw);

      console.log(
        `DEBUG: Found parameter for ${eventValuesVar}: ${parameterIdentifier} = ${parameterValue}`
      );

      if (!eventValuesMap.has(eventValuesVar)) {
        eventValuesMap.set(eventValuesVar, []);
      }
      eventValuesMap.get(eventValuesVar).push({
        parameterType: "generated",
        parameterIdentifier,
        parameterName,
        parameterDescription: `Extracted from migrated SDK`,
        parameterValueType:
          typeof parameterValue === "number" ? "number" : "string",
        parameterValueExample: parameterValue,
      });
    }

    // Extract events
    let match;
    while ((match = eventRegex.exec(code)) !== null) {
      const eventIdentifierRaw = match[1]
        .replace(/AFInAppEventType\./, "")
        .replace(/"/g, "");
      const eventValuesVar = match[2]; // The eventValues variable associated with this event

      const eventIdentifier = formatIdentifier(eventIdentifierRaw);
      const eventName = formatName(eventIdentifierRaw);

      // console.log(`DEBUG: Found Event: ${eventIdentifier}, linked to ${eventValuesVar}`);

      const parameters = eventValuesMap.get(eventValuesVar) || [];

      events.push({
        eventType: "generated",
        eventIdentifier,
        eventName,
        eventPurposeDescription: "Generated from SDK migration",
        eventTriggerDescription: "Triggered in in-app flow",
        parameters,
        selectedEventType: "predefined",
      });
    }

    return {
      eventSetName: "Migrated In-App Events",
      createdAt: Date.now(),
      lastModified: Date.now(),
      events,
    };
  };

  useEffect(() => {
    // Try to retrieve the state from sessionStorage
    const savedState = sessionStorage.getItem("sdkMigrationState");

    if (savedState) {
      const { isConverted, inputCode, newCode } = JSON.parse(savedState);

      setIsConverted(isConverted);
      setInputCode(inputCode);
      setNewCode(newCode);

      console.log("Restored from sessionStorage:", {
        isConverted,
        inputCode,
        newCode,
      });
    }
  }, []);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log("Copied to clipboard:", text);
    });
  };

  const customOverrides = {
    background: "transparent",
    backgroundColor: "transparent",
    padding: "0px 0px",
    margin: "0px",
    fontSize: "13px",
    whiteSpace: "none",
    border: "none",
    fontFamily: "Menlo,Consolas,monospace",
  };

  const renderSyntaxHighlightedContent = (str: string) => (
    <SyntaxHighlighter
      language="javascript"
      style={vs}
      customStyle={customOverrides}
    >
      {str}
    </SyntaxHighlighter>
  );

  const customStyles = {
    variables: {
      light: {
        diffViewerBackground: "#fcfcfc70",
        diffViewerColor: "#212529",
        addedBackground: "#e6ffed",
        addedColor: "#24292e",
        removedBackground: "#ffeef0",
        removedColor: "#24292e",
        wordAddedBackground: "#acf2bd",
        wordRemovedBackground: "#fdb8c0",
        addedGutterBackground: "#cdffd8",
        removedGutterBackground: "#ffdce0",
        gutterBackground: "#f7f7f7",
        gutterBackgroundDark: "#f3f1f1",
        highlightBackground: "#fffbdd",
        highlightGutterBackground: "#fff5b1",
        codeFoldGutterBackground: "#dbedff",
        codeFoldBackground: "#f7f7f7", // exapnd background color
        emptyLineBackground: "#fafbfc",
        gutterColor: "#212529",
        addedGutterColor: "#212529",
        removedGutterColor: "#212529",
        codeFoldContentColor: "#212529",
        diffViewerTitleBackground: "#fafbfc",
        diffViewerTitleColor: "#212529",
        diffViewerTitleBorderColor: "#eee",
      },
      dark: {
        diffViewerBackground: "#1b1c26",
        diffViewerColor: "#FFF",
        addedBackground: "#044B53",
        addedColor: "white",
        removedBackground: "#632F34",
        removedColor: "white",
        wordAddedBackground: "#055d67",
        wordRemovedBackground: "#7d383f",
        addedGutterBackground: "#034148",
        removedGutterBackground: "#632b30",
        gutterBackground: "#292c35",
        gutterBackgroundDark: "#262933",
        highlightBackground: "#2a3967",
        highlightGutterBackground: "#2d4077",
        codeFoldGutterBackground: "#21232b",
        codeFoldBackground: "#262831",
        emptyLineBackground: "#363946",
        gutterColor: "#98a1c6",
        addedGutterColor: "#8c8c8c",
        removedGutterColor: "#8c8c8c",
        codeFoldContentColor: "#555a7b",
        diffViewerTitleBackground: "#2f323e",
        diffViewerTitleColor: "#555a7b",
        diffViewerTitleBorderColor: "#353846",
      },
    },
    codeFold: {
      fontSize: "11.5px",
      fontWeight: "normal",
      color: "#f7f7f7",
    },
    codeFoldContent: {
      color: "rgba(74, 76, 79)",
    },
    codeFoldGutter: {
      backgroundColor: "rgba(74, 76, 79, 0.12)",
    },
    lineNumber: {
      textAlign: "center",
      padding: "0 3px",
      fontSize: ".8em",
    },
    line: {
      whiteSpace: "pre-wrap",
      overflowWrap: "anywhere",
    },
    wordDiff: {
      margin: "0px",
      borderRadius: "3px",
      padding: "0px",
    },
    diffContainer: {
      lineHeight: "20px",
      fontFamily: "Menlo,Consolas,monospace",
      display: "table",
    },
    splitView: {
      // display: "table-row", // Define rows for the diff viewer
    },
    // contentText: {
    //   whiteSpace: "pre-wrap",
    //   overflowWrap: "anywhere",
    // },
    gutter: {
      display: "table-cell",
      width: "40px",
      textAlign: "center",
    },
  } as const;

  const handleToggle = (event) => {
    setSplitView(event.target.checked); // Toggle state based on Switch value
  };

  return (
    <Container maxWidth={"lg"}>
      <Box
        padding={3}
        display={"flex"}
        flexDirection={"column"}
        style={{ minHeight: "50vh" }}
      >
        <Typography variant="h1">
          {`Branch SDK to AppsFlyer SDK migration: ${
            migrationRouteType === "startsdk"
              ? "Start SDK"
              : migrationRouteType === "inapp"
                ? "In-App Events"
                : "Deep Linking"
          }`}
        </Typography>

        <Box display={"flex"} sx={{ mt: 2 }} gap={1} flexDirection={"column"}>
          <>
            {isLoading && (
              <Box mt={20} mb={20} mx="auto" width="fit-content">
                <CircularProgress />
              </Box>
            )}
          </>
          {!isConverted && !isLoading && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              // mx={40}
              width={"100%"}
              gap={2}
              mb={3}
              alignItems={"flex-start"}
            >
              <Note variant="tip" title="" maxWidth={300}>
                Full instructions will arrive soon
              </Note>

              <TextField
                id="multiline3"
                label="Original code"
                variant="outlined"
                placeholder="Paste your Branch SDK code here"
                multiline={true}
                value={inputCode}
                onChange={(e) => {
                  setInputCode(e.target.value);
                }}
                minRows={10}
                maxRows={20}
              />
              <Button
                onClick={migrateCode}
                color={"primary"}
                variant={"contained"}
              >
                Convert
              </Button>
            </Box>
          )}
          {isConverted && !isLoading && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "20px",
                  marginTop: "15px",
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={splitView}
                      onChange={handleToggle}
                      inputProps={{
                        "aria-label": "Toggle Split View",
                      }}
                    />
                  }
                  label="Split View"
                />{" "}
              </div>

              <Box
                // marginTop={2}
                position="relative"
                maxHeight={600}
                overflow={"scroll"}
                borderRadius={"10px"}
              >
                <DiffViewer
                  oldValue={inputCode}
                  newValue={newCode}
                  splitView={splitView} // Enables side-by-side comparison
                  compareMethod={DiffMethod.WORDS} // Compare at word level
                  showDiffOnly={true} // Show only lines with differences
                  renderContent={renderSyntaxHighlightedContent}
                  styles={customStyles} // Apply custom styles
                  useDarkTheme={false}
                  onLineNumberClick={(lineId) => {
                    const lineNumber = parseInt(lineId.slice(2), 10);
                    const isLeftSide = lineId.startsWith("L");

                    const lineContent = isLeftSide
                      ? inputCode.split("\n")[lineNumber - 1]
                      : newCode.split("\n")[lineNumber - 1];

                    if (lineContent) {
                      navigator.clipboard.writeText(lineContent).then(() => {
                        console.log(
                          `Copied line ${lineNumber} content:`,
                          lineContent
                        );
                      });
                    }
                  }}
                />

                {/* Copy Button for Right Side (New Content) */}
                <Box
                  position="absolute"
                  top="4px" // Position at the top of the right side
                  right="5px" // Align to the right
                  zIndex={10}
                  style={{ color: "white" }}
                >
                  <Tooltip placement="top" title="Copy">
                    <IconButton
                      size="small"
                      color="primary"
                      aria-label="Small outlined icon button"
                      onClick={() => copyToClipboard(newCode)}
                      style={{ padding: "3px", border: "1px solid" }} // Change the border color here                      }}
                    >
                      <ContentCopyRoundedIcon
                        fontSize="small"
                        style={{ cursor: "pointer", fontSize: "12px" }} // Custom font size
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={1}
                width={"fit-content"}
                marginTop={3}
                alignSelf={"center"}
              >
                {" "}
                <Button
                  color={"primary"}
                  variant={"outlined"}
                  onClick={() => retry()}
                >
                  Retry
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={4}
        textAlign="end"
      >
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        {isConverted && !isLoading && (
          <Button
            color={"primary"}
            variant={"contained"}
            onClick={() => proceedToTest()}
          >
            Procced to test
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default transition(SdkMigration);
