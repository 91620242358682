import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Button, Box, Container } from "@mui/material";
import {
  IProgress,
  ISessionProps,
  // SDKTestType,
  // SessionStatus,
} from "../components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { InteractiveCard, Typography } from "@appsflyer/fe-ui-core";
import CardContent from "@mui/material/CardContent";
import transition from "../components/transition";

const getRouteType = (pathname: string) => {
  console.log("Pathname:", pathname);

  if (pathname.includes("/inapp")) return "inapp";
  if (pathname.includes("/startsdk")) return "startsdk";
  if (pathname.includes("/deeplink")) return "deeplink";
  return "inapp"; // Default fallback
};

const ChoosePathMigration = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // Ensure migrationRouteType is valid
  let migrationRouteType = sessionStorage.getItem("migrationRouteType") || "";
  if (!["startsdk", "deeplink"].includes(migrationRouteType)) {
    migrationRouteType = "inapp"; // Default to inapp
    sessionStorage.setItem("migrationRouteType", migrationRouteType);
  }

  useEffect(() => {
    // Retrieve the previously stored route
    const previousRoute = sessionStorage.getItem("previousPage") || "/";

    console.log("Previous Route:", previousRoute);

    const routeType = getRouteType(previousRoute); // Detect route type from stored route
    console.log("Detected route type:", routeType);

    sessionStorage.setItem("migrationRouteType", routeType);
  }, []);

  const toContinueRegular = () => {
    // Initialize progress with a copy of progressArr
    const progressArr: IProgress[] = location.state?.progress.slice();
    progressArr.push({
      id: "startsdk_startSDK",
      text: "progress_startsdk_startSDK",
      order: 5,
    });

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      currentPage: (location.state?.currentPage || 0) + 1,
      path: migrationRouteType,
    };

    if (migrationRouteType == "inapp") {
      navigate(`/inapp/${location.state?.os}/sendinapp`, {
        state: progressState,
      });
    } else if (migrationRouteType == "deeplink") {
      navigate(`/deeplink/${location.state?.os}/implement`, {
        state: progressState,
      });
    } else {
      navigate(
        `/${migrationRouteType}/${location.state.os.toLowerCase()}/startsdk`,
        {
          state: progressState,
        }
      );
    }
    // navigate(`/startsdk/${location.state.os.toLowerCase()}/startsdk`, {
    //   state: progressState, // Passes the updated state
    // });
  };

  const toMigration = () => {
    sessionStorage.setItem("previousRoute", location.pathname);

    // Initialize progress with a copy of progressArr
    const progressArr: IProgress[] = location.state?.progress.slice();

    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      // sdkTestType: SDKTestType.Manual,
      // sessionStatus: SessionStatus.IncompleteTesting,
      currentPage: (location.state?.currentPage || 0) + 1,
      path: migrationRouteType, 
    };

    navigate(
      `/migration/${migrationRouteType}/${location.state.os}/sdkMigration${location.search}`,
      {
        state: progressState,
      }
    );
  };

  const cardStyle = {
    width: 240,
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    padding: 15,
  };

  return (
    <Container maxWidth={"lg"}>
      <Box
        textAlign={"center"}
        marginTop={5}
        marginBottom={5}
        padding={5}
        style={{ minHeight: "25vh" }}
      >
        <Typography variant="h1">{"Choose how to continue"}</Typography>
        <Box
          display={"flex"}
          sx={{ mt: 6 }}
          gap={2}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <InteractiveCard style={cardStyle} onClick={() => toMigration()}>
            <CardContent style={{ textAlign: "left" }}>
              <Typography variant="h3">{"Migration from Branch"}</Typography>
            </CardContent>
          </InteractiveCard>
          <InteractiveCard style={cardStyle} onClick={() => toContinueRegular()}>
            <CardContent style={{ textAlign: "left" }}>
              <Typography variant="h3">{"Normal SDK Integration"}</Typography>
            </CardContent>
          </InteractiveCard>
        </Box>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        {/* <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => toContinueRegular()}
        >
          Skip
        </Button> */}
      </Box>
    </Container>
  );
};

export default transition(ChoosePathMigration);
