import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./EvgenWrapper.css";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { EvgenEvent, EvgenEventsObject } from "./IEvgen";
import {
  ISessionProps,
  OSType,
  SDKTestType,
} from "../main/components/IProgress";
import { Button, Chip, CircularProgress } from "@mui/material";
import AmplitudeLink from "../services/AmplitudeLink";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { Typography, Note, Tooltip } from "@appsflyer/fe-ui-core";
import { useTranslation } from "react-i18next";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { v4 as uuidv4 } from "uuid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const VerticalContainer = styled(Box)(({ theme }) => ({
  gap: theme.spacing(4),
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
}));

const PREFIX = "HelpOutlineIcon";
const classes = {
  root: `${PREFIX}-root`,
};

const StyledHelpOutlineIcon = styled(HelpOutlineIcon)(({ theme }) => ({
  [`&.${classes.root}`]: {
    color: theme.palette.grey[500],
    "&:hover": {
      color: theme.palette.grey[600],
    },
  },
}));

const EvgenWrapper = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isTestingStarted, setIsTestingStarted] = useState(false);
  const [activeEvent, setActiveEvent] = useState<EvgenEvent>();
  const [activeEventName, setActiveEventName] = useState("");

  const [evgenEventsObject, setEvgenEventsObject] =
    useState<EvgenEventsObject>();

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event, newValue) => {
    console.log(evgenEventsObject?.events);
    setIsTestingStarted(false);
    setIsLoading(true);
    setValue(newValue);
    setActiveStep(0);

    const newEvent = evgenEventsObject.events[newValue];
    setActiveEvent(newEvent);
    setActiveEventName(newEvent.eventName);
    const progressState: ISessionProps = {
      ...location.state,
      sessionId: uuidv4(),
      eventName: newEvent.eventIdentifier,
      eventParams: newEvent.parameters.map(
        (param) => param.parameterIdentifier
      ),
    };
    setIsLoading(false);
    navigate(location.pathname, { replace: true, state: progressState });
  };

  useEffect(() => {
    let stateData = location.state;

    if (!stateData) {
      console.warn(
        "WARNING: No state found, retrieving from sessionStorage..."
      );
      stateData = JSON.parse(sessionStorage.getItem("evgenState"));
    }

    if (!stateData?.sdkTestType) {
      console.error("ERROR: sdkTestType is still missing!");
    } else {
      console.log("DEBUG: sdkTestType is restored:", stateData.sdkTestType);
    }
    setEvgenEventsObject(location.state.evgenEventsObject);
    const firstEvent: EvgenEvent = location.state?.evgenEventsObject?.events[0];
    setValue(0);
    setActiveEvent(firstEvent);
    setActiveEventName(firstEvent.eventName);
    const progressState: ISessionProps = {
      ...location.state,
      eventName: firstEvent.eventIdentifier,
      eventParams: firstEvent.parameters.map(
        (param) => param.parameterIdentifier
      ),
    };

    navigate(location.pathname, { replace: true, state: progressState });
  }, []);

  const steps = [
    {
      label: t("ip_TestInAppIntegration_ios_stepOneHeading"),
      component: (
        <>
          {location.state?.sdkTestType === SDKTestType.Manual ? (
            <>
              <ul>
                <li>
                  <Typography variant="body1">
                    {t("ip_TestInAppIntegration_ios_stepOneContent")}
                    <br />
                    {t("ip_TestInAppIntegration_ios_stepOneContent_b")}
                    <b>{t("ip_TestInAppIntegration_ios_stepOneContent_c")}</b>
                    {t("ip_TestInAppIntegration_ios_stepOneContent_d")}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>
                      {t("startSDK_TestSDKIntegration_android_Step2") + ": "}
                    </span>
                    <AmplitudeLink
                      target="_blank"
                      href={
                        location.state?.os === OSType.Android
                          ? `https://${
                              location.state.lang != "en"
                                ? location.state.lang + "."
                                : ""
                            }dev.appsflyer.com/hc/docs/integrate-android-sdk#enabling-debug-mode`
                          : `https://${
                              location.state.lang != "en"
                                ? location.state.lang + "."
                                : ""
                            }dev.appsflyer.com/hc/docs/integrate-ios-sdk#enabling-debug-mode`
                      }
                    >
                      {t("startSDK_TestSDKIntegration_android_EnableDebugMode")}
                    </AmplitudeLink>
                  </Typography>
                </li>
              </ul>
            </>
          ) : (
            <>
              <Typography variant="body1">
                {t("ip_TestInAppIntegration_ios_stepOneContent")}
                <br />
                {t("ip_TestInAppIntegration_ios_stepOneContent_b")}
                <b>{t("ip_TestInAppIntegration_ios_stepOneContent_c")}</b>
                {t("ip_TestInAppIntegration_ios_stepOneContent_d")}
              </Typography>
            </>
          )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("ip_TestInAppIntegration_ios_stepThreeHeading"),
      component: (
        <>
          <Typography>
            {t("ip_TestInAppIntegration_ios_stepThreeContent")}
            <b>{t("ip_TestInAppIntegration_ios_stepThreeContent2")}</b>
          </Typography>
          {location.state?.os === OSType.iOS &&
            location.state?.deviceType === "IDFA" && (
              <Box mt={2}>
                <Note>
                  <Typography style={{ display: "inline" }}>
                    {t("testConsole_TestType_myDeviceID_iosAtt")}
                  </Typography>
                </Note>
              </Box>
            )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  const isEventPassed = (eventName: string) => {
    return (
      (location.state as ISessionProps).evgenEventsObject.passedEvents?.indexOf(
        eventName
      ) > -1
    );
  };

  return (
    <div>
      <>
        <Box padding={3} style={{ minHeight: "60vh" }}>
          {evgenEventsObject?.events.length ? (
            <Box>
              <Box mb={2} width={"100%"}>
                <Button
                  variant="outlined"
                  size="medium"
                  color="secondary"
                  style={{ marginRight: "5px" }}
                  disabled={isLoading}
                  onClick={() => navigate(-1)}
                >
                  {t("general_Back")}
                </Button>
              </Box>
              <Box sx={{ mb: 2, mx: 1 }} display={"flex"}>
                <Typography
                  sx={{ borderRight: "1px solid black", width: 210 }}
                  variant={"h2"}
                >
                  {t("testConsole_Evgen_chooseevent")}
                </Typography>
                <Box sx={{ ml: 2 }} display={"flex"}>
                  <Typography
                    variant={"h3"}
                    sx={{ fontWeight: "300 !important" }}
                  >
                    {t("testConsole_Evgen_selectedevent")}{" "}
                    <b>
                      {activeEventName ?? t("testConsole_Evgen_pleaseselect")}
                    </b>
                  </Typography>
                  <Box mt={1.5}>
                    <Tooltip
                      // disableInteractive={false}
                      maxWidth={1000}
                      enterTouchDelay={700}
                      variant="info"
                      title={
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  {t("ip_AppEvents_Evgen_ename")}
                                </TableCell>
                                <TableCell>
                                  {t("ip_AppEvents_Evgen_epurpose")}
                                </TableCell>
                                <TableCell>
                                  {t("ip_AppEvents_Evgen_etrigger")}
                                </TableCell>
                                <TableCell>
                                  {t("ip_AppEvents_Evgen_eparams")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {activeEvent && (
                                <TableRow
                                  key={activeEvent.eventIdentifier}
                                  hover
                                >
                                  <TableCell>
                                    <Box
                                      display={"flex"}
                                      alignItems={"center"}
                                      flexDirection={"column"}
                                      gap={1}
                                    >
                                      <div>
                                        <Chip
                                          label={activeEvent.eventType ?? ""}
                                          size="medium"
                                          variant="outlined"
                                          color={
                                            activeEvent.eventType ==
                                            "predefined"
                                              ? "primary"
                                              : "success"
                                          }
                                        />
                                      </div>
                                      <code>{activeEvent.eventIdentifier}</code>
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    {activeEvent.eventPurposeDescription}
                                  </TableCell>
                                  <TableCell>
                                    {activeEvent.eventTriggerDescription}
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      display={"flex"}
                                      flexDirection={"column"}
                                      gap={1}
                                    >
                                      {activeEvent.parameters.map(
                                        (param, idx) => {
                                          return (
                                            <span
                                              key={
                                                param.parameterIdentifier +
                                                "_" +
                                                idx
                                              }
                                            >
                                              <code>
                                                {param.parameterIdentifier}
                                              </code>
                                            </span>
                                          );
                                        }
                                      )}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      }
                    >
                      <StyledHelpOutlineIcon
                        sx={{
                          position: "absolute",
                          marginTop: "2px",
                          marginLeft: "2px",
                        }}
                        className={classes.root}
                        fontSize="small"
                        color="action"
                      />
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              <hr></hr>
              <VerticalContainer>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  orientation="vertical"
                  aria-label="tabs vertical"
                  variant="scrollable"
                  scrollButtons={false}
                  className="evgentest"
                  visibleScrollbar
                  sx={{
                    borderRight: 1,
                    borderColor: "divider",
                    minWidth: 200,
                  }}
                >
                  {evgenEventsObject?.events.map((event, index) => {
                    return (
                      <Tab
                        key={`tab-${index}`}
                        value={index}
                        // value={event.eventIdentifier}
                        // {...tabProps("tab-" + index)}
                        wrapped
                        label={<Typography>{event.eventName}</Typography>}
                        icon={
                          isEventPassed(event.eventIdentifier) ? (
                            <CheckCircleIcon />
                          ) : (
                            <></>
                          )
                        }
                        iconPosition={"end"}
                      />
                    );
                  })}
                </Tabs>
                {/* Evgen automated testing */}
                {(location.state as ISessionProps).sdkTestType ===
                  SDKTestType.Automated && (
                  <>
                    {!isTestingStarted ? (
                      <Box>
                        <Stepper
                          activeStep={activeStep}
                          style={{ border: "none" }}
                          orientation="vertical"
                        >
                          {steps.map(
                            ({
                              label,
                              component,
                              shouldDisplayNavigationButtons,
                            }) => (
                              <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent>
                                  {component}
                                  {shouldDisplayNavigationButtons && (
                                    <Box sx={{ mt: 2 }}>
                                      {activeStep !== 0 && (
                                        <Button
                                          variant="text"
                                          color="primary"
                                          onClick={handleBack}
                                          // sx={{ mt: 2, mr: 1 }}
                                        >
                                          {t("general_Back")}
                                        </Button>
                                      )}
                                      {activeStep !== steps.length - 1 && (
                                        <Button
                                          // sx={{ mt: 2 }}
                                          variant="contained"
                                          color="primary"
                                          onClick={handleNext}
                                        >
                                          {activeStep === steps.length - 1
                                            ? t("general_Finish")
                                            : t("general_Next")}
                                        </Button>
                                      )}
                                    </Box>
                                  )}
                                </StepContent>
                              </Step>
                            )
                          )}
                        </Stepper>
                        <Button
                          onClick={() => setIsTestingStarted(true)}
                          color={"primary"}
                          variant={"contained"}
                        >
                          Start testing
                        </Button>
                      </Box>
                    ) : (
                      <>{!isLoading && <Outlet />}</>
                    )}
                  </>
                )}
                {/* Evgen manual testing */}
                {(location.state as ISessionProps).sdkTestType ===
                  SDKTestType.Manual && (
                  <>{isLoading ? <CircularProgress /> : <Outlet />}</>
                )}
              </VerticalContainer>
            </Box>
          ) : (
            <Box>
              <Box sx={{ mb: 50, mx: 1 }}>
                <Box>
                  <Typography variant={"h2"}>No events found</Typography>
                </Box>
                <Box mt={2} width={"100%"}>
                  <Button
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    style={{ marginRight: "5px" }}
                    disabled={isLoading}
                    onClick={() => navigate(-1)}
                  >
                    {t("general_Back")}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </>
    </div>
  );
};

export default EvgenWrapper;
