import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Alert,
  Box,
  CircularProgress,
  Container,
  styled,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { Typography, Note, Collapse } from "@appsflyer/fe-ui-core";
import {
  getGCD,
  testConsoleEvents,
  testConsoleOnelinkSig,
} from "../../services/api";
import {
  IProgress,
  ISessionProps,
  IntegrationPathType,
  OSType,
  SDKTestType,
  SessionStatus,
} from "../components/IProgress";
import { DJSimpleCodeBlock, ICodeBlock } from "../components/codeblock";
import transition from "../components/transition";
import { DeeplinkType } from "../../deeplinking/DeferredOrDirect";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  amplitudeTrack_changeToManual,
  amplitudeTrack_continue,
  amplitudeTrack_getResults,
  creatDLObject,
  sendGtagOnce,
} from "../../services/utils";
import AmplitudeLink from "../../services/AmplitudeLink";
import StartFunctionExplanation from "../components/StartFunctionExp";
import { v4 as uuidv4 } from "uuid";

interface TestConsoleEvent {
  timestamp_sdk: string;
  event_type: string;
  appsflyer_id: string;
  event_name: string;
  event_value: string;
  sdk_version: string;
  app_version: string;
  customer_user_id: string;
  idfa: string;
  idfv: string;
  gaid: string;
  android_id: string;
  imei: string;
  oaid: string;
  amazon_aid: string;
  af_deeplink: string;
}

const TestConsole_Results = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [deviceId, setDeviceId] = useState("");
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(null);
  const [confettiSrc, setConfettiSrc] = useState(
    process.env.PUBLIC_URL + "/images/confetti.gif" + "?a=" + Math.random()
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [isCollapseOpenAttr, setIsCollapseOpenAttr] = useState(false);
  const [severity, setSeverity] = useState<
    "success" | "info" | "warning" | "error"
  >("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [jsonEvents, setJsonEvents] = useState({});
  const [jsonEventsAttr, setJsonEventsAttr] = useState({});
  const [jsonEventsShortLink, setJsonEventsShortLink] = useState({});
  const [passed, setPassed] = useState(false);
  const [inappMismatch, setInappMismatch] = useState(false);
  const [inappParamMismatch, setInappParamMismatch] = useState(false);
  const [missingParamsList, setMissingParams] = useState([]);
  const [errorRes, setErrorRes] = useState("");
  const [isErrored, setIsErrored] = useState(false);
  const [isNotSigned, setIsNotSigned] = useState(false);
  const [didSendGtag, setDidSendGtag] = useState(false);
  const [devKey, setDevKey] = useState("");
  const [retriesCounter, setRetriesCounter] = useState(0);
  const basePath = location.pathname.startsWith("/migration") ? "/migration" : "";

  useEffect(() => {
    const lsDevKey = localStorage.getItem("devKey");
    if (lsDevKey) {
      const dKey = JSON.parse(lsDevKey);
      setDevKey(dKey);
      console.log("DEBUG: Dev Key found:", dKey);

      if (
        (location.state as ISessionProps).path === IntegrationPathType.DeepLink
      ) {
        const onelink: URL = new URL(
          location.state.oneLinkURL.indexOf("http") > -1
            ? location.state.oneLinkURL
            : "https://" + location.state.oneLinkURL
        );
        const oneLinkId = onelink.pathname.split("/")[1];
        const shortLinkId = onelink.pathname.split("/")[2];

        testConsoleOnelinkSig(dKey, oneLinkId, shortLinkId)
          ?.then((res) => {
            if (res?.status === "Error") {
              setErrorRes(res.data.error);
              console.log("onelink sig error:", res.data.error);
            } else if (res?.status === "Success") {
              setJsonEventsShortLink(res.data);
            } else {
              console.error("Unexpected response:", res);
              setSnackbarMessage("Unexpected response:" + res);
              setErrorRes(res);
              setIsErrored(true);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }

    if (location.state?.deviceId && location.state?.token) {
      console.log("DEBUG: deviceId and token exist:", location.state.deviceId, location.state.token);

      setLoading(true);
      setTimeout(() => {
        console.log("DEBUG: Calling testConsoleEvents API");
        testConsoleEvents(
          location.state?.appID,
          location.state?.deviceId,
          location.state?.token
        )
          ?.then((res) => {
            console.log("DEBUG: API response received:", res);
            handleResults(res);
          })
          .catch((err) => {
            console.error("ERROR: API call failed:", err);
            setLoading(false);
          });
      }, 6000);
    }

    const lsToken = localStorage.getItem("token");
    if (lsToken) {
      setToken(JSON.parse(lsToken));
    }
    const lsDeviceId = localStorage.getItem(`deviceId_${location.state?.os}`);
    if (lsDeviceId) {
      setDeviceId(JSON.parse(lsDeviceId));
    }
  }, []);

  const refreshResults = () => {
    setRetriesCounter(retriesCounter + 1);
    setSnackbarMessage("");

    setLoading(true);
    // restart the confetti gif
    setConfettiSrc(
      process.env.PUBLIC_URL + "/images/confetti.gif" + "?a=" + Math.random()
    );
    const progressState: ISessionProps = {
      ...location.state,
    };

    setTimeout(() => {
      testConsoleEvents(progressState.appID, deviceId, token)
        ?.then((res) => {
          handleResults(res);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }, 6000);
  };

  const creatDLObj = (json) => {
    if (
      (location.state as ISessionProps).path !== IntegrationPathType.DeepLink
    ) {
      return;
    }
    return creatDLObject(location.state, json);
  };

  const handleResults = (res) => {
    console.log("DEBUG: handleResults() was called with response:", res);
    setLoading(true);
    let res_passed, res_jsonEvents, res_jsonEventsAttr;
    if (res?.status === "Error") {
      setSnackbarMessage(res.data.error);
      setSeverity("error");
      setIsOpen(true);
      setPassed(false);
      setIsErrored(true);
      setErrorRes(res.data.error);
      res_passed = false;
      res_jsonEvents = {};
      res_jsonEventsAttr = {};
    } else if (res?.status === "Success") {
      setSeverity("success");
      setIsErrored(false);
      setIsOpen(false);
      setSnackbarMessage(res.data.message);
      setJsonEvents(res.data.data);
      console.log("res.data.data: ", res.data.data);

      // check if any results are back
      if (res.data.data.length) {
        switch (location.state?.path) {
          case IntegrationPathType.InApp:
            if (res.data.data.some((e) => e["event_type"] === "In-App")) {
              const filteredInappEvents: TestConsoleEvent[] =
                res.data.data.filter(
                  (e: TestConsoleEvent) => e.event_type === "In-App"
                );
              setJsonEvents(filteredInappEvents);
              res_jsonEvents = filteredInappEvents;
              // console.log("DEBUG: Checking event match:", location.state?.eventName);
              // console.log("DEBUG: Available events in filteredInappEvents:", filteredInappEvents.map(e => e.event_name));
                            if (
                filteredInappEvents.find(
                  (event) => event.event_name === location.state?.eventName
                )
              ) {
                console.log("DEBUG: Matched event:", location.state?.eventName);
                setPassed(true);
                res_passed = true;
                setInappMismatch(false);

                // if the event name matches, check the inapp params as well
                const params: string[] = location.state?.eventParams;
                setInappParamMismatch(false);
                const missingParams: Array<string[]> = [];
                for (
                  let index = 0;
                  index < filteredInappEvents.length;
                  index++
                ) {
                  missingParams.push([]);
                }
                params.forEach((param) => {
                  // search for all the params within the received event value
                  filteredInappEvents.forEach((event, idx) => {
                    if (event.event_value.indexOf(param) < 0) {
                      // if one of them has not been found, set to not passed
                      setPassed(false);
                      res_passed = false;
                      setInappParamMismatch(true);
                      missingParams[idx].push(param);
                    }
                  });
                });
                console.log("missingParams", missingParams);

                setMissingParams(missingParams);
              } else {
                console.warn("WARNING: No match found for event name:", location.state?.eventName);
                setInappMismatch(true);
                // if the event name does not match, the inapp params will not be checked
                setInappParamMismatch(false);
                setPassed(false);
                res_passed = false;
              }
            } else {
              setInappMismatch(false);
              setInappParamMismatch(false);
              setPassed(false);
              res_passed = false;
            }
            break;
          case IntegrationPathType.StartSDK:
            if (res.data.data.some((e) => e["event_type"] === "Session")) {
              const firstRes = res.data.data.filter(
                (e) => e["event_type"] === "Session"
              )[0];
              setJsonEvents(firstRes);
              const afid = firstRes["appsflyer_id"];
              setPassed(true);
              res_passed = true;
              res_jsonEvents = firstRes;
              getGCD(afid, devKey, location.state?.appID)
                ?.then((gcdRes) => {
                  setJsonEventsAttr(gcdRes.data);
                  // check the GCD results
                  res_jsonEventsAttr = gcdRes.data;
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              setPassed(false);
              setJsonEvents({});
              setJsonEventsAttr({});
              res_passed = false;
              res_jsonEvents = {};
              res_jsonEventsAttr = {};
            }
            break;
          case IntegrationPathType.DeepLink: {
            const firstEvent = res.data.data.filter(
              (e) => e["event_type"] === "Session"
            )[0];
            setJsonEvents(firstEvent);
            res_jsonEvents = firstEvent;
            if (location.state?.deeplinkType === DeeplinkType.Deferred) {
              const AFID = res.data.data.filter(
                (e) => e["event_type"] === "Session"
              )[0]["appsflyer_id"];
              if (AFID) {
                getGCD(AFID, devKey, location.state?.appID)?.then((gcdRes) => {
                  setJsonEventsAttr(gcdRes.data);
                  res_jsonEventsAttr = gcdRes.data;

                  if (gcdRes?.data?.status_code === 404) {
                    setPassed(false);
                    res_passed = false;
                  } else if (
                    gcdRes.data?.af_dp !== undefined ||
                    gcdRes.data?.af_sub1 !== undefined
                  ) {
                    setPassed(true);
                    res_passed = true;
                  } else {
                    setPassed(false);
                    res_passed = false;
                  }
                });
              } else {
                setJsonEventsAttr({});
                res_jsonEventsAttr = {};
              }
            } else if (location.state?.deeplinkType === DeeplinkType.Direct) {
              const lastRes = res.data.data.filter(
                (e) => e["event_type"] === "Session"
              )[0]["af_deeplink"];
              if (lastRes.indexOf(location.state?.oneLinkURL) > -1) {
                setPassed(true);
                res_passed = true;
                setIsNotSigned(false);
              } else {
                setIsNotSigned(true);
                setPassed(false);
                res_passed = false;
              }
            }
            break;
          }
          default:
            break;
        }
      } else {
        setPassed(false);
        res_passed = false;
        setInappMismatch(false);
        setInappParamMismatch(false);
      }

      localStorage.setItem(
        `deviceId_${location.state?.os}`,
        JSON.stringify(location.state?.deviceId)
      );
      localStorage.setItem("token", JSON.stringify(location.state?.token));
    } else {
      console.error("Unexpected response:", res);
      setSnackbarMessage("Unexpected response:" + res);
      setPassed(false);
      setErrorRes(res);
      setIsErrored(true);
      setInappMismatch(false);
      setInappParamMismatch(false);
      res_passed = false;
      res_jsonEvents = {};
      res_jsonEventsAttr = {};
    }

    setTimeout(() => {
      const newS = setNewState(res_passed, res_jsonEvents, res_jsonEventsAttr);
      const navPath = location.state.evgenEventsObject
        ? `${basePath}/${location.state.path}/${location.state.os}/evgenevents`
        : `${basePath}/${location.state.path}/${location.state.os}/eventstestconsole`;
      navigate(navPath, {
        replace: true,
        state: newS,
      });
    }, 300);
  };

  const handleCloseSnackbar = (
    event: SyntheticEvent<Element, Event>,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };

  const codeProps: ICodeBlock = {
    codePrimary: JSON.stringify(jsonEvents, null, 2),
    language: "json",
    showLineNumbers: true,
  };

  const codePropsAttr: ICodeBlock = {
    codePrimary: JSON.stringify(jsonEventsAttr, null, 2),
    language: "json",
    showLineNumbers: true,
  };

  // creatDeferredObj
  const codePropsShortlink: ICodeBlock = {
    codePrimary: `[DDL] Calling onDeepLinking with: 
{"deepLink":"${JSON.stringify(creatDLObj(jsonEventsShortLink), null, 2)}",
"status":"FOUND"}`,
    language: "java",
    showLineNumbers: true,
  };

  const ConfettiImg = styled("img")({
    position: "fixed",
    marginLeft: 100,
    transform: "translate(-50%, -50%)",
    zIndex: 5,
    pointerEvents: "none",
  });

  const finishProcess = () => {
    const progressState: ISessionProps = setNewState(
      passed,
      jsonEvents,
      jsonEventsAttr
    );

    if (location.state?.path === IntegrationPathType.DeepLink) {
      if (location.state?.deeplinkType == DeeplinkType.Deferred) {
        setDoneAndGoBackDL(true, progressState);
        return;
      } else if (location.state?.deeplinkType == DeeplinkType.Direct) {
        setDoneAndGoBackDL(false, progressState);
        return;
      }
    }
    amplitudeTrack_continue(progressState);

    navigate("/finish", { state: progressState });
  };

  function setNewState(
    passed: boolean,
    jsonEvents: object,
    jsonEventsAttr: object
  ): ISessionProps {
    const progressState: ISessionProps = {
      ...location.state,
      currentPage: location.state?.currentPage + 1,
      completionStatus: passed,
      retriesCounter: retriesCounter,
      sessionStatus: passed ? SessionStatus.Success : SessionStatus.Failed,
      completionResult: JSON.stringify({
        testData: jsonEvents,
        gcdData: jsonEventsAttr,
      }),
    };

    // send gtag for testing success if passed
    if (passed && !didSendGtag) {
      sendGtagOnce(
        "automated_test_success_fixed",
        "TestConsole_Results_" + location.state.path,
        location.state.sessionId
      );
      setDidSendGtag(true);
    }

    // send amplitude event
    amplitudeTrack_getResults(progressState);
    console.log("location.state.path", location.state.path);
    console.log("Array.isArray(jsonEvents)",Array.isArray(jsonEvents));

    if (
      location.state.path === IntegrationPathType.InApp &&
      Array.isArray(jsonEvents)
    ) {
      progressState.eventName_received =
        (jsonEvents as Array<TestConsoleEvent>)
          .map((x) => x["event_name"])
          .join(", ") ?? "";
      progressState.eventParams_received =
        (jsonEvents as Array<TestConsoleEvent>)
          .map((x) => x["event_value"])
          .join(", ") ?? "";
      if (passed && progressState.isEvgenTesting) {
        if (!progressState.evgenEventsObject.passedEvents?.length) {
          progressState.evgenEventsObject.passedEvents = Array<string>();
        }
        if (
          progressState.evgenEventsObject.passedEvents.indexOf(
            progressState.eventName_received
          ) < 0
        )
          progressState.evgenEventsObject.passedEvents.push(
            progressState.eventName
          );
      }
    }
    setLoading(false);

    return progressState;
  }

  const setDoneAndGoBackDL = (
    isDeferred: boolean,
    progressState: ISessionProps
  ) => {
    if (isDeferred) {
      localStorage.setItem(
        `isDeferredDone_${location.state?.os}_${location.state?.appID}`,
        JSON.stringify(passed)
      );
      progressState.isDeferredDone = passed;
    } else {
      localStorage.setItem(
        `isDirectDone_${location.state?.os}_${location.state?.appID}`,
        JSON.stringify(passed)
      );
      progressState.isDirectDone = passed;
    }

    navigate(`${basePath}/deeplink/${location.state?.os}/deeplinktype`, {
      state: progressState,
    });
  };

  const noteStyle = {
    minWidth: "300px",
    maxWidth: "600px",
    maxHeight: "fit-content",
    overflowWrap: "anywhere",
    // margin: "0 10px",
    alignItems: "flex-start",
  };

  const noteStyleSecondary = {
    width: "450px",
    marginBottom: "0.5em",
  };

  const ariaLabelPrefix = isCollapseOpen ? "Show Less" : "Show More";
  const ariaLabelPrefixAttr = isCollapseOpenAttr ? "Show Less" : "Show More";

  const toManualTest = () => {
    const newStateArr: IProgress[] = (
      location.state.progress as IProgress[]
    ).filter((x) => x.order < (location.state.os == OSType.Android ? 6 : 5));
    newStateArr.push({
      id: "startsdk_testintegration_manual",
      text: "progress_startsdk_testintegration_manual",
      order: 7,
    });

    const progressState: ISessionProps = {
      ...location.state,
      progress: newStateArr,
      sdkTestType: SDKTestType.Manual,
      sessionStatus: SessionStatus.IncompleteTesting,
      currentPage: location.state?.currentPage - 2,
      startTime: new Date(),
      updateTime: new Date(),
      sessionId: uuidv4(),
    };

    amplitudeTrack_continue(progressState, { testType: SDKTestType.Manual });
    amplitudeTrack_changeToManual(progressState);

    navigate(`${basePath}/startsdk/${progressState.os}/testsdkintegration`, {
      state: progressState,
    });
  };

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        {location.state?.path === IntegrationPathType.StartSDK && (
          <Typography variant="h1">
            {t("startSDK_Step5_android_TestSDKIntegration")}
          </Typography>
        )}
        {location.state?.path === IntegrationPathType.InApp && (
          <Typography variant="h1">
            {t("ip_TestInAppIntegration_android_testSDKIntegrationHeading")}
          </Typography>
        )}
        <Box display={"flex"} gap={2} justifyContent={"space-between"}>
          <Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={2}
            >
              <>
                {loading ? (
                  <Box my={8} mx={8}>
                    <CircularProgress />
                  </Box>
                ) : passed ? (
                  <>
                    <Box>
                      <Box mt={4}>
                        <Typography variant="h2">
                          {t("testConsole_TestResults_TestResults")}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          bgcolor={"#258750"}
                          color={"white"}
                          width={100}
                          height={30}
                          mt={2}
                          fontWeight={"bold"}
                          borderRadius={"20px"}
                        >
                          {t("testConsole_TestResults_Passed")}
                        </Box>
                        {!loading && (
                          <ConfettiImg
                            alt="confetti"
                            src={confettiSrc}
                          ></ConfettiImg>
                        )}
                      </Box>
                      <Typography style={{ margin: "5px 0 " }}></Typography>
                      <Box
                        display={"flex"}
                        my={4}
                        sx={{ flexDirection: { md: "row", xs: "column" } }}
                      >
                        <Collapse
                          buttonVariant="text"
                          isOpen={isCollapseOpen}
                          onClick={() => setIsCollapseOpen((o) => !o)}
                          title={t("testConsole_TestResults_ReceivedTestData")}
                          ButtonProps={{
                            IconProps: {
                              "aria-label": `${ariaLabelPrefix} Top Expand Icon`,
                            },
                          }}
                        >
                          <DJSimpleCodeBlock {...codeProps}></DJSimpleCodeBlock>
                        </Collapse>
                        {(location.state?.path !==
                          IntegrationPathType.DeepLink ||
                          location.state?.deeplinkType !==
                            DeeplinkType.Direct) && (
                          <>
                            {location.state?.path !==
                              IntegrationPathType.InApp && (
                              <Collapse
                                buttonVariant="text"
                                isOpen={isCollapseOpenAttr}
                                onClick={() => setIsCollapseOpenAttr((o) => !o)}
                                title={t(
                                  "testConsole_TestResults_ReceivedAttributionData"
                                )}
                                ButtonProps={{
                                  IconProps: {
                                    "aria-label": `${ariaLabelPrefixAttr} Top Expand Icon`,
                                  },
                                }}
                              >
                                <DJSimpleCodeBlock
                                  {...codePropsAttr}
                                ></DJSimpleCodeBlock>
                              </Collapse>
                            )}
                          </>
                        )}
                      </Box>
                      <Box
                        display={"flex"}
                        my={4}
                        gap={1}
                        flexDirection={"row-reverse"}
                        justifyContent={"flex-end"}
                        // sx={{ flexDirection: { md: "row", xs: "column" } }}
                      >
                        {location.state?.path === IntegrationPathType.InApp && (
                          <>
                            {!inappMismatch && (
                              <Note
                                title={""}
                                icon={<CheckCircleIcon color={"success"} />}
                                style={noteStyle}
                              >
                                <Box>
                                  <b>{t("testConsole_inapp_match_title")}</b>
                                  <br />
                                  <br />
                                  <Typography>
                                    {t("testConsole_inapp_match_text") + " "}
                                    <b>{location.state?.eventName}</b>
                                  </Typography>
                                  <br />
                                  <Typography>
                                    {t("testConsole_inapp_match_text_b") + " "}
                                  </Typography>
                                  <ul>
                                    {(
                                      jsonEvents as Array<TestConsoleEvent>
                                    ).map((x, idx) => (
                                      <li key={idx} style={{ marginLeft: -25 }}>
                                        <b>Event {idx + 1}:</b>{" "}
                                        {x["event_name"]}
                                      </li>
                                    ))}
                                  </ul>
                                </Box>
                              </Note>
                            )}
                            {!inappParamMismatch && (
                              <Note
                                title={""}
                                icon={<CheckCircleIcon color={"success"} />}
                                style={noteStyle}
                              >
                                <b>
                                  {t("testConsole_inapp_match_param_title")}
                                </b>
                                <br />
                                <br />
                                <Typography>
                                  {t("testConsole_inapp_match_param_text") +
                                    " "}
                                  <b>
                                    {`["${location.state?.eventParams.join(
                                      `", "`
                                    )}"]`}
                                  </b>
                                </Typography>
                                <br />
                                <Typography>
                                  {t("testConsole_inapp_match_param_text_b") +
                                    " "}
                                </Typography>
                                <ul>
                                  {Array.isArray(jsonEvents) &&
                                    (jsonEvents as Array<TestConsoleEvent>).map(
                                      (x, idx) => (
                                        <li
                                          key={idx}
                                          style={{ marginLeft: -30 }}
                                        >
                                          <b>Event {idx + 1}:</b>{" "}
                                          {x["event_value"]}
                                        </li>
                                      )
                                    )}
                                </ul>
                              </Note>
                            )}
                          </>
                        )}
                        {location.state?.path ===
                          IntegrationPathType.DeepLink &&
                          location.state?.deeplinkType ===
                            DeeplinkType.Direct && (
                            <Note
                              title={""}
                              icon={<CheckCircleIcon />}
                              style={{ maxWidth: "400px", margin: "10px 0" }}
                            >
                              {t("testConsole_dl_match_text") + " "}
                              <br />
                              <code style={{ overflowWrap: "anywhere" }}>
                                {jsonEvents["af_deeplink"]}
                              </code>
                            </Note>
                          )}
                      </Box>
                      {/* check udl note box VVV */}
                      {location.state?.path ===
                        IntegrationPathType.DeepLink && (
                        <Box mt={2}>
                          <Note title={""} style={{ maxWidth: "700px" }}>
                            <Typography style={{ display: "inline" }}>
                              <b>
                                {t("testConsole_TestResults_Passed_CheckUDL")}
                              </b>
                            </Typography>
                            <Typography style={{ margin: "10px 0" }}>
                              {t(
                                "testConsole_TestResults_Passed_CheckUDL_text"
                              )}
                              <code>onDeepLinking.</code>
                              {t(
                                "testConsole_TestResults_Passed_CheckUDL_text"
                              )}
                              <code>status=FOUND</code>
                              {t(
                                "testConsole_TestResults_Passed_CheckUDL_text_c"
                              )}
                            </Typography>
                            <Box style={{ maxWidth: "650px" }}>
                              <DJSimpleCodeBlock
                                {...codePropsShortlink}
                              ></DJSimpleCodeBlock>
                            </Box>
                          </Note>
                        </Box>
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>
                      <Box my={6}>
                        <Typography variant="h2">
                          {t("testConsole_TestResults_TestResults")}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          bgcolor={"#D83A52"}
                          color={"white"}
                          width={100}
                          height={30}
                          mt={2}
                          fontWeight={"bold"}
                          borderRadius={"20px"}
                        >
                          {t("testConsole_TestResults_Failed")}
                        </Box>
                      </Box>

                      <Box my={4}>
                        <Box
                          display={"flex"}
                          gap={3}
                          flexDirection={"row-reverse"}
                          justifyContent={"flex-end"}
                          // sx={{ flexDirection: { md: "row", xs: "column" } }}
                        >
                          {(isErrored && !errorRes) ||
                          Object.keys(jsonEvents).length === 0 ? (
                            <Typography>
                              <b>
                                {t("testConsole_TestResults_ReceivedTestData") +
                                  ": "}{" "}
                              </b>
                              {t("testConsole_TestResults_NoResults")}
                            </Typography>
                          ) : (
                            <>
                              <Collapse
                                buttonVariant="text"
                                isOpen={isCollapseOpen}
                                onClick={() => setIsCollapseOpen((o) => !o)}
                                title={
                                  isErrored
                                    ? t("testConsole_TestResults_ErrorData")
                                    : t(
                                        "testConsole_TestResults_ReceivedTestData"
                                      )
                                }
                                ButtonProps={{
                                  IconProps: {
                                    "aria-label": `${ariaLabelPrefix} Top Expand Icon`,
                                  },
                                }}
                              >
                                <>
                                  <DJSimpleCodeBlock
                                    {...codeProps}
                                  ></DJSimpleCodeBlock>
                                  {/* <code>{errorRes}</code> */}
                                </>
                              </Collapse>
                              {isNotSigned && (
                                <Note title={""} style={{ maxWidth: "300px" }}>
                                  <Typography style={{ display: "inline" }}>
                                    {t("testConsole_notSigned_title")} (
                                    <code>af_deeplink</code>{" "}
                                    {t("testConsole_notSigned_title_b")}).
                                  </Typography>
                                  <ol>
                                    <li>{t("testConsole_notSigned_uri")}</li>
                                    <li>
                                      {t("testConsole_notSigned_uri2") + " "}
                                      <code> scheme </code>{" "}
                                      {t("testConsole_notSigned_uri2_b")}
                                      <code> af_deeplink </code>{" "}
                                      {t("testConsole_notSigned_uri2_c")}{" "}
                                      <code> https </code>.
                                    </li>
                                  </ol>
                                </Note>
                              )}
                            </>
                          )}
                          {inappMismatch && (
                            <Note
                              title={""}
                              icon={<WarningIcon />}
                              style={noteStyle}
                            >
                              <b>{t("testConsole_inapp_mismatch_title")}</b>
                              <br />
                              <br />
                              <Typography>
                                {t("testConsole_inapp_mismatch_text") + " "}
                                <b>{location.state?.eventName}</b>
                              </Typography>
                              <br />
                              <Typography>
                                {t("testConsole_inapp_mismatch_text_b") + " "}
                              </Typography>
                              <ul>
                                {Array.isArray(jsonEvents) &&
                                  (jsonEvents as Array<TestConsoleEvent>).map(
                                    (x, idx) => (
                                      <li key={idx} style={{ marginLeft: -30 }}>
                                        <b>Event {idx + 1}:</b>{" "}
                                        {x["event_name"]}
                                      </li>
                                    )
                                  )}
                              </ul>
                            </Note>
                          )}
                          {inappParamMismatch && (
                            <Note
                              title={""}
                              icon={<WarningIcon />}
                              style={noteStyle}
                            >
                              <b>
                                {t("testConsole_inapp_mismatch_param_title")}
                              </b>
                              <br />
                              <br />
                              <Typography>
                                {t("testConsole_inapp_mismatch_param_text") +
                                  " "}
                                <b>
                                  {`["${location.state?.eventParams.join(
                                    `", "`
                                  )}"]`}
                                </b>
                              </Typography>
                              <br />
                              <Typography>
                                {t("testConsole_inapp_mismatch_param_text_b") +
                                  " "}
                              </Typography>
                              <ul>
                                {Array.isArray(jsonEvents) &&
                                  (jsonEvents as Array<TestConsoleEvent>).map(
                                    (x, idx) => (
                                      <li key={idx} style={{ marginLeft: -30 }}>
                                        <b>Event {idx + 1}:</b>{" "}
                                        {x["event_value"]}
                                      </li>
                                    )
                                  )}
                              </ul>
                              <br />
                              <Typography>
                                {t("testConsole_inapp_match_param_text_c") +
                                  " "}
                              </Typography>
                              <ul>
                                {Array.isArray(jsonEvents) &&
                                  missingParamsList.map((event, idx) => (
                                    <li key={idx} style={{ marginLeft: -30 }}>
                                      <b>Event {idx + 1}:</b>{" "}
                                      {event?.join(", ")}
                                    </li>
                                  ))}
                              </ul>
                            </Note>
                          )}
                          {(location.state?.path !==
                            IntegrationPathType.DeepLink ||
                            location.state?.deeplinkType !==
                              DeeplinkType.Direct) && (
                            <>
                              {!jsonEventsAttr ||
                              Object.keys(jsonEventsAttr).length === 0 ? (
                                <>
                                  {location.state?.path !==
                                    IntegrationPathType.InApp && (
                                    <Typography>
                                      <b>
                                        {t(
                                          "testConsole_TestResults_ReceivedAttributionData"
                                        ) + ": "}
                                      </b>{" "}
                                      {t("testConsole_TestResults_NoResults")}
                                    </Typography>
                                  )}
                                </>
                              ) : (
                                <>
                                  {location.state?.path !==
                                    IntegrationPathType.InApp && (
                                    <Collapse
                                      buttonVariant="text"
                                      isOpen={isCollapseOpenAttr}
                                      onClick={() =>
                                        setIsCollapseOpenAttr((o) => !o)
                                      }
                                      title={t(
                                        "testConsole_TestResults_ReceivedAttributionData"
                                      )}
                                      ButtonProps={{
                                        IconProps: {
                                          "aria-label": `${ariaLabelPrefixAttr} Top Expand Icon`,
                                        },
                                      }}
                                    >
                                      <DJSimpleCodeBlock
                                        {...codePropsAttr}
                                      ></DJSimpleCodeBlock>
                                    </Collapse>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Box>
                      </Box>
                      <Typography variant="h3" mt={5}>
                        {t("dl_androidtestdirect2_android_subtitle_b")}
                      </Typography>
                      <Box width={500} mt={1}>
                        {location.state?.path === IntegrationPathType.InApp && (
                          <Box my={2}>
                            <Note variant="tip" style={noteStyleSecondary}>
                              {t(
                                "testConsole_TestResults_TroubleshootingTips_trigger"
                              )}
                            </Note>
                          </Box>
                        )}
                        {location.state.path ===
                          IntegrationPathType.StartSDK && (
                          <Note title={""} style={noteStyleSecondary}>
                            <Typography>
                              {t(
                                "testConsole_TestResults_TroubleshootingTips_launch"
                              )}
                            </Typography>
                          </Note>
                        )}
                        {location.state.path ===
                          IntegrationPathType.StartSDK && (
                          <Note title={""} style={noteStyleSecondary}>
                            <Typography>
                              {t(
                                "testConsole_TestResults_TroubleshootingTips_start"
                              )}
                              <code>
                                start()
                                <StartFunctionExplanation />
                              </code>
                              <span style={{ marginLeft: 18 }}>
                                {t(
                                  "testConsole_TestResults_TroubleshootingTips_start_b"
                                )}
                              </span>
                            </Typography>
                          </Note>
                        )}
                        {location.state.os == OSType.iOS && (
                          <Note title={""} style={noteStyleSecondary}>
                            <Typography>
                              {t("testConsole_TestType_myDeviceID_iosAtt")}
                            </Typography>
                          </Note>
                        )}
                        {location.state.path ===
                          IntegrationPathType.StartSDK && (
                          <Note title={""} style={noteStyleSecondary}>
                            <Typography>
                              {t(
                                "testConsole_TestResults_TroubleshootingTips_manual"
                              ) + " "}
                              <br />
                              <Button
                                variant={"contained"}
                                size={"medium"}
                                sx={{ mt: 1 }}
                                onClick={() => toManualTest()}
                              >
                                {t(
                                  "testConsole_TestResults_TroubleshootingTips_manual_toManual"
                                )}
                              </Button>
                            </Typography>
                          </Note>
                        )}
                        <Note title={""} style={noteStyleSecondary}>
                          <Typography>
                            {t("testConsole_TestResults_RunTipsHere") + " "}
                            <AmplitudeLink
                              ml={0.5}
                              mr={0.5}
                              target="_blank"
                              href={`https://${
                                location.state.lang != "en"
                                  ? location.state.lang + "."
                                  : ""
                              }dev.appsflyer.com/hc/docs/testing-android#troubleshooting-the-android-sdk-integration`}
                            >
                              {t(
                                "testConsole_TestResults_TroubleshootingSection"
                              )}
                            </AmplitudeLink>
                            {" " + t("testConsole_TestResults_InDevHub")}
                          </Typography>
                        </Note>
                      </Box>
                    </Box>
                  </>
                )}
              </>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => refreshResults()}
                  disabled={loading}
                  size="medium"
                  style={{ marginRight: "5px" }}
                >
                  {t("StartSDK_testSDK_ios_testResult_runTestAgain")}
                </Button>
              </Box>
              <Snackbar
                open={isOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                style={{ position: "sticky" }}
              >
                <Alert
                  variant="filled"
                  onClose={handleCloseSnackbar}
                  severity={severity}
                  sx={{ width: "100%" }}
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          disabled={loading}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          disabled={loading}
          onClick={() => finishProcess()}
        >
          {t("testConsole_TestResults_Exit")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(TestConsole_Results);
