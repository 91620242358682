/* eslint-disable */
import { Alert, Box, Container } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IProgress,
  ISessionProps,
  IntegrationPathType,
  OSType,
  SDKTestType,
  SessionStatus,
} from "../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { Typography, Note } from "@appsflyer/fe-ui-core";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { DJSimpleCodeBlock, ICodeBlock } from "../main/components/codeblock";
import transition from "../main/components/transition";
import { DeeplinkType } from "./DeferredOrDirect";
import { Button, Snackbar } from "@mui/material";
import { testConsoleOnelinkSig } from "../services/api";
import {
  amplitudeTrack_continue,
  creatDLObject,
  sendGtagOnce,
} from "../services/utils";
import AmplitudeLink from "../services/AmplitudeLink";

const TestDeferredDL = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [QRvalue, setQRValue] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [severity, setSeverity] = useState<
    "success" | "info" | "warning" | "error"
  >("success");
  const [jsonEventsShortLink, setJsonEventsShortLink] = useState({});
  const basePath = location.pathname.startsWith("/migration") ? "/migration" : "";

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseSnackbar = (
    event: SyntheticEvent<Element, Event>,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    setQRValue(location.state?.oneLinkURL || "");

    const lsDevKey = localStorage.getItem("devKey");
    if (lsDevKey) {
      const dKey = JSON.parse(lsDevKey);

      if (
        (location.state as ISessionProps).path === IntegrationPathType.DeepLink
      ) {
        const onelink: URL = new URL(
          location.state.oneLinkURL.indexOf("http") > -1
            ? location.state.oneLinkURL
            : "https://" + location.state.oneLinkURL
        );
        const oneLinkId = onelink.pathname.split("/")[1];
        const shortLinkId = onelink.pathname.split("/")[2];

        testConsoleOnelinkSig(dKey, oneLinkId, shortLinkId)
          ?.then((res) => {
            if (res?.status === "Error") {
              console.log("onelink sig error:", res.data.error);
              setSeverity("error");
              setSnackbarMessage(res.data.error);
            } else if (res?.status === "Success") {
              setJsonEventsShortLink(res.data);
              setSeverity("success");
            } else {
              console.error("Unexpected response:", res);
              setSeverity("error");
              setSnackbarMessage("Unexpected response:" + res);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, []);

  const setDoneAndGoBack = () => {
    // Manual Testing
    if (location.state?.sdkTestType === SDKTestType.Manual) {
      if (location.state?.deeplinkType == DeeplinkType.Deferred) {
        // Deferred
        localStorage.setItem(
          `isDeferredDone_${location.state?.os}_${location.state?.appID}`,
          "true"
        );
        const progressState: ISessionProps = {
          ...location.state,
          isDeferredDone: true,
          sessionStatus: SessionStatus.Success,
        };
        // send gtag for manual testing success
        sendGtagOnce(
          "manual_test_success_fixed",
          "TestDeferredDL",
          location.state.sessionId
        );

        amplitudeTrack_continue(location.state);

        navigate(`${basePath}/deeplink/${location.state?.os}/deeplinktype`, {
          state: progressState,
        });
      } else {
        // Direct
        const progressState: ISessionProps = {
          ...location.state,
          sessionStatus: SessionStatus.Success,
        };
        // send gtag for manual testing success
        sendGtagOnce(
          "manual_test_success_fixed",
          "TestDeferredDL",
          location.state.sessionId
        );

        amplitudeTrack_continue(location.state);

        navigate(`${basePath}/deeplink/${location.state?.os}/testDirect`, {
          state: progressState,
        });
      }
    } else {
      // Automated Testing
      const progressArr: IProgress[] = location.state?.progress.slice();
      progressArr.push({
        id: "dl_testevents",
        text: "progress_getres",
        order: 8,
      });

      const progressState: ISessionProps = {
        ...location.state,
        progress: progressArr,
        currentPage: location.state?.currentPage + 1,
      };

      amplitudeTrack_continue(location.state);

      navigate(`${basePath}/deeplink/${location.state?.os}/eventstestconsole`, {
        state: progressState,
      });
    }
  };

  const androidProps: ICodeBlock[] = [
    {
      codePrimary: `[DDL] Calling onDeepLinking with: 
{"deepLink":"{\"campaign_id\":\"\",\"af_sub3\":\"\",\"match_type\":\"probabilistic\",\"af_sub1\":\"\",
\"deep_link_value\":\"apples\",\"campaign\":\"\",\"af_sub4\":\"\",\"timestamp\":\"2022-12-06T11:47:40.037\",
\"click_http_referrer\":\"\",\"af_sub5\":\"\",\"media_source\":\"\",\"af_sub2\":\"\",\"deep_link_sub1\":\"23\",
\"is_deferred\":true}","status":"FOUND"}`,
      showLineNumbers: true,
    },
  ];

  const iosProps: ICodeBlock[] = [
    {
      codePrimary: `[AppsFlyerSDK] [com.appsflyer.serial] [DDL] Calling didResolveDeepLink with: 
  {"af_sub4":"","click_http_referrer":"","af_sub1":"","click_event":{"af_sub4":"","click_http_referrer":"",
  "af_sub1":"","af_sub3":"","deep_link_value":"peaches","campaign":"","match_type":"probabilistic"
  ,"af_sub5":"","campaign_id":"","media_source":"","deep_link_sub1":"23","af_sub2":""}
  ,"af_sub3":"","deep_link_value":"peaches","campaign":"","match_type":"probabilistic","af_sub5":"",
  "media_source":"","campaign_id":"","af_sub2":""}`,
      showLineNumbers: true,
      language: "java"
    },
  ];

  const codePropsShortlink: ICodeBlock = {
    codePrimary: `[DDL] Calling onDeepLinking with: 
{"deepLink":"${JSON.stringify(
      creatDLObject(location.state, jsonEventsShortLink),
      null,
      2
    )}",
"status":"FOUND"}`,
    showLineNumbers: true,
    language: "java"
  };

  const steps_deferred = [
    {
      label: t("dl_deferred_prep"),
      component: (
        <>
          {location.state?.sdkTestType === SDKTestType.Manual ? (
            <>
              <ul>
                <li>
                  <Typography variant="body1">
                    {t("dl_deferred_prep_text")}
                    <b>{t("dl_deferred_prep_text_b")}</b>
                    {t("dl_deferred_prep_text_c")}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>
                      {t("startSDK_TestSDKIntegration_android_Step2") + ": "}
                    </span>
                    <AmplitudeLink
                      target="_blank"
                      href={
                        location.state.os === OSType.Android
                          ? `https://${
                              location.state.lang != "en"
                                ? location.state.lang + "."
                                : ""
                            }dev.appsflyer.com/hc/docs/integrate-android-sdk#enabling-debug-mode`
                          : `https://${
                              location.state.lang != "en"
                                ? location.state.lang + "."
                                : ""
                            }dev.appsflyer.com/hc/docs/integrate-ios-sdk#enabling-debug-mode`
                      }
                    >
                      {t("startSDK_TestSDKIntegration_android_EnableDebugMode")}
                    </AmplitudeLink>
                  </Typography>
                </li>
              </ul>
            </>
          ) : (
            <>
              <Typography variant="body1">
                {t("dl_deferred_prep_text")}
                <b>{t("dl_deferred_prep_text_b")}</b>
                {t("dl_deferred_prep_text_c")}
              </Typography>
            </>
          )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_deferred_step1"),
      component: (
        <>
          <Typography>
            {t("dl_deferred_step1_text") + " "}
            <AmplitudeLink href={QRvalue} target="_blank">
              {t("dl_deferred_step1_link")}
            </AmplitudeLink>
          </Typography>
          <QRCode
            size={256}
            style={{
              height: "auto",
              maxWidth: "200px",
              width: "200px",
              display: "block",
              margin: "20px 0",
            }}
            value={QRvalue}
            viewBox={`0 0 256 256`}
          />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_deferred_step3"),
      component: (
        <>
          <Typography>{t("dl_deferred_step3_text1")}</Typography>
          <ul>
            <li>
              <Typography>{t("dl_deferred_step3_text_b")}</Typography>
              <img
                src={process.env.PUBLIC_URL + "/images/unavailable.png"}
                style={{
                  margin: "10px 0",
                  width: "200px",
                  display: "block",
                }}
                alt="App is unavailable"
              />
              <Typography sx={{ mt: 3 }}>
                {t("dl_deferred_step3_text_c")}
                <b>{t("dl_deferred_step3_text3_b")}</b>
              </Typography>
            </li>
            <li style={{ marginTop: 10 }}>
              <Typography>
                {t("dl_deferred_step2_text1")}
                {location.state?.os == OSType.Android
                  ? " Google Play "
                  : " the App Store "}
                {t("dl_deferred_step2_text_b")}
              </Typography>
            </li>
          </ul>
          {location.state?.os === OSType.iOS &&
            location.state?.deviceType === "IDFA" && (
              <Note>
                <Typography style={{ display: "inline" }}>
                  {t("testConsole_TestType_myDeviceID_iosAtt")}
                </Typography>
              </Note>
            )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_deferred_step3_text_d"),
      component: <></>,
      shouldDisplayNavigationButtons: true,
    },
  ];

  if (location.state?.sdkTestType === SDKTestType.Manual) {
    steps_deferred.push({
      label: t("dl_deferred_step4"),
      component: (
        <>
          {location.state?.os === OSType.Android && (
            <>
              <Typography sx={{ mt: 2 }}>
                {t("dl_page19_android_udlGotResponse")}
              </Typography>
              <DJSimpleCodeBlock {...codePropsShortlink}></DJSimpleCodeBlock>
            </>
          )}
          {location.state?.os === OSType.iOS && (
            <>
              <Typography sx={{ mt: 2 }}>
                {t("dl_21_ios_udl_sends_query")}
              </Typography>
              <DJSimpleCodeBlock {...codePropsShortlink}></DJSimpleCodeBlock>
            </>
          )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    });
  }

  const steps_direct = [
    {
      label: t("ip_TestInAppIntegration_ios_stepOneHeading"),
      component: (
        <>
          {location.state?.sdkTestType === SDKTestType.Manual ? (
            <>
              <ul>
                <li>
                  <Typography variant="body1">
                    {t("ip_TestInAppIntegration_ios_stepOneContent")}
                  </Typography>
                </li>
                <li>
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>
                      {t("startSDK_TestSDKIntegration_android_Step2") + ": "}
                    </span>
                    <AmplitudeLink
                      target="_blank"
                      href={
                        location.state?.os === OSType.Android
                          ? `https://${
                              location.state.lang != "en"
                                ? location.state.lang + "."
                                : ""
                            }dev.appsflyer.com/hc/docs/integrate-android-sdk#enabling-debug-mode`
                          : `https://${
                              location.state.lang != "en"
                                ? location.state.lang + "."
                                : ""
                            }dev.appsflyer.com/hc/docs/integrate-ios-sdk#enabling-debug-mode`
                      }
                    >
                      {t("startSDK_TestSDKIntegration_android_EnableDebugMode")}
                    </AmplitudeLink>
                  </Typography>
                </li>
              </ul>
            </>
          ) : (
            <>
              <Typography variant="body1">
                {t("ip_TestInAppIntegration_ios_stepOneContent")}
              </Typography>
            </>
          )}
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
    {
      label: t("dl_deferred_step1"),
      component: (
        <>
          <Typography>
            {t("dl_deferred_step1_text") + " "}
            <AmplitudeLink href={QRvalue} target="_blank">
              {t("dl_deferred_step1_link")}
            </AmplitudeLink>
          </Typography>
          <QRCode
            size={256}
            style={{
              height: "auto",
              maxWidth: "200px",
              width: "200px",
              display: "block",
              margin: "20px 0",
            }}
            value={QRvalue}
            viewBox={`0 0 256 256`}
          />
        </>
      ),
      shouldDisplayNavigationButtons: true,
    },
  ];

  const steps =
    location.state?.deeplinkType == DeeplinkType.Deferred
      ? steps_deferred
      : steps_direct;

  return (
    <Container maxWidth={"lg"}>
      <Box padding={3} style={{ minHeight: "50vh" }}>
        <Box marginBottom={2}>
          {location.state?.deeplinkType == DeeplinkType.Deferred && (
            <Typography variant="h1">
              {t("dl_19_ios_test_deferred_linking")}
            </Typography>
          )}
          {location.state?.deeplinkType == DeeplinkType.Direct && (
            <Typography variant="h1">
              {t("dl_22_ios_test_deferred_linking")}
            </Typography>
          )}
        </Box>
        <Stepper
          activeStep={activeStep}
          style={{ border: "none" }}
          orientation="vertical"
        >
          {steps.map(({ label, component, shouldDisplayNavigationButtons }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {component}
                {shouldDisplayNavigationButtons && (
                  <Box sx={{ mt: 2 }}>
                    {activeStep !== 0 && (
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleBack}
                        // sx={{ mt: 2, mr: 1 }}
                      >
                        {t("general_Back")}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        // sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? t("general_Finish")
                          : t("general_Next")}
                      </Button>
                    )}
                  </Box>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <Snackbar
          open={isOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          style={{ position: "sticky" }}
        >
          <Alert
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          disabled={activeStep != steps.length - 1}
          onClick={() => setDoneAndGoBack()}
        >
          {location.state?.sdkTestType === SDKTestType.Manual &&
            location.state?.deeplinkType !== DeeplinkType.Direct &&
            t("dl_general_choose_dl")}
          {location.state?.sdkTestType === SDKTestType.Automated &&
            t("testconsole_getRes")}
          {location.state?.sdkTestType === SDKTestType.Manual &&
            location.state?.deeplinkType == DeeplinkType.Direct &&
            t("general_Continue")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(TestDeferredDL);
