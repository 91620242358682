import { Box, Container, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IProgress,
  ISessionProps,
  SessionStatus,
} from "../main/components/IProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@appsflyer/fe-ui-core";
import CheckIcon from "@mui/icons-material/Check";
import transition from "../main/components/transition";
import { v4 as uuidv4 } from "uuid";
import {
  amplitudeTrack_continue,
  amplitudeTrack_linkClick,
} from "../services/utils";
import AmplitudeLink from "../services/AmplitudeLink";

export enum DeeplinkType {
  Direct = "direct",
  Deferred = "deferred",
}

const DeferredOrDirectAndroid = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation(); 
  const [progress, setProgress] = useState<IProgress[]>([]);
  const [isDeferredDone, setIsDeferredDone] = useState(false);
  const [isDirectDone, setIsDirectDone] = useState(false);
  const basePath = location.pathname.startsWith("/migration") ? "/migration" : "";

  useEffect(() => {
    if (location.state) {
      const state: ISessionProps = location.state as ISessionProps;
      setProgress(state?.progress);
      setIsDirectDone(
        localStorage.getItem(
          `isDirectDone_${location.state?.os}_${location.state?.appID}`
        ) == "true"
      );
      setIsDeferredDone(
        localStorage.getItem(
          `isDeferredDone_${location.state?.os}_${location.state?.appID}`
        ) == "true"
      );
    }
  }, [location.state]);

  const handleDLChoice = (deeplinkType: DeeplinkType) => {
    const progressArr: IProgress[] = progress.slice();
    const progressState: ISessionProps = {
      ...location.state,
      progress: progressArr,
      deeplinkType: deeplinkType,
      currentPage: location.state?.currentPage + 1,
    };

    // create new session when user is testing another path (Direct/Deferred)
    if (
      progressState.sessionStatus === SessionStatus.Success ||
      progressState.sessionStatus === SessionStatus.Failed
    ) {
      progressState.sessionId = uuidv4();
      progressState.sessionStatus = SessionStatus.ImplementedNotTested;
    }

    amplitudeTrack_continue(location.state, { deeplinkType });

    navigate(`${basePath}/deeplink/${progressState.os}/testtype`, {
      state: progressState,
    });
  };

  function finishProcess() {
    // localStorage.removeItem(`isDirectDone_${location.state?.os}_${location.state?.appID}`);
    // localStorage.removeItem(`isDeferredDone_${location.state?.os}_${location.state?.appID}`);
    const progressState: ISessionProps = {
      ...location.state,
      currentPage: location.state?.currentPage + 1,
      isDirectDone: isDirectDone,
      isDeferredDone: isDeferredDone,
      completionStatus: true,
    };

    navigate("/finish", { state: progressState });
  }

  return (
    <Container maxWidth={"lg"}>
      <Box
        textAlign={"center"}
        marginTop={5}
        marginBottom={5}
        padding={5}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        style={{ minHeight: "50vh" }}
      >
        <Box marginBottom={2}>
          <Typography variant="h1">
            {t("dl_choosepath_android_pageTitle")}
          </Typography>
        </Box>

        <Box
          display={"flex"}
          marginTop={5}
          gap={1}
          justifyContent={"center"}
          sx={{ flexDirection: { md: "row", xs: "column" } }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Button
              variant="outlined"
              color="primary"
              style={{ height: "100px" }}
              onClick={() => handleDLChoice(DeeplinkType.Deferred)}
              endIcon={isDeferredDone && <CheckIcon />}
            >
              {t("dl_choosepath_android_deferred")}
            </Button>
            <AmplitudeLink
              href="https://www.appsflyer.com/glossary/deferred-deep-linking/"
              target="_blank"
              rel="noopener noreferrer"
              mx={2}
              mt={2}
              onClick={() =>
                amplitudeTrack_linkClick(
                  location.state,
                  "https://www.appsflyer.com/glossary/deferred-deep-linking/"
                )
              }
            >
              <Typography>
                {t("dl_choosepath_android_deferred_readmore")}
              </Typography>
            </AmplitudeLink>
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Button
              endIcon={isDirectDone && <CheckIcon />}
              variant="outlined"
              color="primary"
              style={{ height: "100px" }}
              onClick={() => handleDLChoice(DeeplinkType.Direct)}
            >
              {t("dl_choosepath_android_direct")}
            </Button>
            <AmplitudeLink
              href="https://www.appsflyer.com/glossary/deep-linking/"
              target="_blank"
              rel="noopener noreferrer"
              mx={2}
              mt={2}
              onClick={() =>
                amplitudeTrack_linkClick(
                  location.state,
                  "https://www.appsflyer.com/glossary/deep-linking/"
                )
              }
            >
              <Typography>
                {t("dl_choosepath_android_direct_readmore")}
              </Typography>
            </AmplitudeLink>
          </Box>
        </Box>
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent="space-between"
        alignSelf="end"
        justifySelf="end"
        marginTop={10}
        textAlign="end"
      >
        {" "}
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          style={{ marginRight: "5px" }}
          onClick={() => navigate(-1)}
        >
          {t("general_Back")}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          disabled={!isDeferredDone && !isDirectDone}
          onClick={() => finishProcess()}
        >
          {t("ip_InAppResults_android_doneButton")}
        </Button>
      </Box>
    </Container>
  );
};

export default transition(DeferredOrDirectAndroid);
