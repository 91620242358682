// // event_parameter_name
// exports.androidEventParameterNames = [
//     "af_content", "af_achievement_id", "af_level", "af_score", "af_success",
//     "af_price", "af_content_type", "af_content_id", "af_content_list",
//     "af_currency", "af_quantity", "af_registration_method", "af_payment_info_available",
//     "af_max_rating_value", "af_rating_value", "af_search_string", "af_date_a",
//     "af_date_b", "af_destination_a", "af_destination_b", "af_description", "af_class",
//     "af_event_start", "af_event_end", "af_lat", "af_long", "af_customer_user_id",
//     "af_validated", "af_revenue", "af_projected_revenue", "af_receipt_id",
//     "af_tutorial_id", "af_virtual_currency_name", "af_deep_link", "af_old_version",
//     "af_new_version", "af_review_text", "af_coupon_code", "af_order_id", "af_param_1",
//     "af_param_2", "af_param_3", "af_param_4", "af_param_5", "af_param_6", "af_param_7",
//     "af_param_8", "af_param_9", "af_param_10", "af_departing_departure_date",
//     "af_returning_departure_date", "af_destination_list", "af_city", "af_region",
//     "af_country", "af_departing_arrival_date", "af_returning_arrival_date",
//     "af_suggested_destinations", "af_travel_start", "af_travel_end", "af_num_adults",
//     "af_num_children", "af_num_infants", "af_suggested_hotels", "af_user_score",
//     "af_hotel_score", "af_purchase_currency", "af_preferred_neighborhoods",
//     "af_preferred_num_stops", "af_adrev_ad_type", "af_adrev_network_name",
//     "af_adrev_placement_id", "af_adrev_ad_size", "af_adrev_mediated_network_name",
//     "af_preferred_price_range", "af_preferred_star_ratings"
//   ];
//   // android_constant_name
// exports.androidParameterConstantNames = [
//     "CONTENT", "ACHIEVEMENT_ID", "LEVEL", "SCORE", "SUCCESS", "PRICE", "CONTENT_TYPE",
//     "CONTENT_ID", "CONTENT_LIST", "CURRENCY", "QUANTITY", "REGISTRATION_METHOD",
//     "PAYMENT_INFO_AVAILABLE", "MAX_RATING_VALUE", "RATING_VALUE", "SEARCH_STRING",
//     "DATE_A", "DATE_B", "DESTINATION_A", "DESTINATION_B", "DESCRIPTION", "CLASS",
//     "EVENT_START", "EVENT_END", "LAT", "LONG", "CUSTOMER_USER_ID", "VALIDATED",
//     "REVENUE", "PROJECTED_REVENUE", "RECEIPT_ID", "TUTORIAL_ID", "VIRTUAL_CURRENCY_NAME",
//     "DEEP_LINK", "OLD_VERSION", "NEW_VERSION", "REVIEW_TEXT", "COUPON_CODE", "ORDER_ID",
//     "PARAM_1", "PARAM_2", "PARAM_3", "PARAM_4", "PARAM_5", "PARAM_6", "PARAM_7", "PARAM_8",
//     "PARAM_9", "PARAM_10", "DEPARTING_DEPARTURE_DATE", "RETURNING_DEPARTURE_DATE",
//     "DESTINATION_LIST", "CITY", "REGION", "COUNTRY", "DEPARTING_ARRIVAL_DATE",
//     "RETURNING_ARRIVAL_DATE", "SUGGESTED_DESTINATIONS", "TRAVEL_START", "TRAVEL_END",
//     "NUM_ADULTS", "NUM_CHILDREN", "NUM_INFANTS", "SUGGESTED_HOTELS", "USER_SCORE",
//     "HOTEL_SCORE", "PURCHASE_CURRENCY", "PREFERRED_NEIGHBORHOODS", "PREFERRED_NUM_STOPS",
//     "AD_REVENUE_AD_TYPE", "AD_REVENUE_NETWORK_NAME", "AD_REVENUE_PLACEMENT_ID",
//     "AD_REVENUE_AD_SIZE", "AD_REVENUE_MEDIATED_NETWORK_NAME", "PREFERRED_PRICE_RANGE",
//     "PREFERRED_STAR_RATINGS"
//   ];
//   // Type
// exports.types = [
//     "String[]", "String", "String", "String", "String", "float", "String",
//     "String", "String[]", "String", "int", "String", "String", "String", "String",
//     "String", "String", "String", "String", "String", "String", "String", "String",
//     "String", "String", "String", "String", "boolean", "int", "int", "String",
//     "String", "String", "String", "String", "String", "String", "String", "String",
//     "String", "String", "String", "String", "String", "String", "String", "String",
//     "String", "String", "String", "String", "String[]", "String", "String", "String",
//     "String", "String", "String[]", "String", "String", "String", "String", "String",
//     "String[]", "String", "String", "String", "String[]", "String", "String", "String",
//     "String", "String", "String", "String", "String"
//   ];
//   exports.androidTypeEvents=[
//     { name: "af_level_achieved", constant: "AFInAppEventType.LEVEL_ACHIEVED" },
//     { name: "af_add_payment_info", constant: "AFInAppEventType.ADD_PAYMENT_INFO" },
//     { name: "af_add_to_cart", constant: "AFInAppEventType.ADD_TO_CART" },
//     { name: "af_add_to_wishlist", constant: "AFInAppEventType.ADD_TO_WISHLIST" },
//     { name: "af_complete_registration", constant: "AFInAppEventType.COMPLETE_REGISTRATION" },
//     { name: "af_tutorial_completion", constant: "AFInAppEventType.TUTORIAL_COMPLETION" },
//     { name: "af_initiated_checkout", constant: "AFInAppEventType.INITIATED_CHECKOUT" },
//     { name: "af_purchase", constant: "AFInAppEventType.PURCHASE" },
//     { name: "af_rate", constant: "AFInAppEventType.RATE" },
//     { name: "af_search", constant: "AFInAppEventType.SEARCH" },
//     { name: "af_spent_credits", constant: "AFInAppEventType.SPENT_CREDITS" },
//     { name: "af_achievement_unlocked", constant: "AFInAppEventType.ACHIEVEMENT_UNLOCKED" },
//     { name: "af_content_view", constant: "AFInAppEventType.CONTENT_VIEW" },
//     { name: "af_list_view", constant: "AFInAppEventType.LIST_VIEW" },
//     { name: "af_travel_booking", constant: "AFInAppEventType.TRAVEL_BOOKING" },
//     { name: "af_share", constant: "AFInAppEventType.SHARE" },
//     { name: "af_invite", constant: "AFInAppEventType.INVITE" },
//     { name: "af_login", constant: "AFInAppEventType.LOGIN" },
//     { name: "af_re_engage", constant: "AFInAppEventType.RE_ENGAGE" },
//     { name: "af_update", constant: "AFInAppEventType.UPDATE" },
//     { name: "af_location_coordinates", constant: "AFInAppEventType.LOCATION_COORDINATES" },
//     { name: "af_customer_segment", constant: "AFInAppEventType.CUSTOMER_SEGMENT" },
//     { name: "af_subscribe", constant: "AFInAppEventType.SUBSCRIBE" },
//     { name: "af_start_trial", constant: "AFInAppEventType.START_TRIAL" },
//     { name: "af_ad_click", constant: "AFInAppEventType.AD_CLICK" },
//     { name: "af_ad_view", constant: "AFInAppEventType.AD_VIEW" },
//     { name: "af_opened_from_push_notification", constant: "AFInAppEventType.OPENED_FROM_PUSH_NOTIFICATION" }
// ]
//   exports.androidParameter = exports.androidEventParameterNames.map((name, index) => ({
//     name,
//     constant: exports.androidParameterConstantNames[index],
//     type:exports.types[index],
//   }));
  

exports.androidParams=[
  {
    "event_parameter_name": "af_content",
    "android_constant_name": "CONTENT",
    "Type": "String[]"
  },
  {
    "event_parameter_name": "af_achievement_id",
    "android_constant_name": "ACHIEVEMENT_ID",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_level",
    "android_constant_name": "LEVEL",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_score",
    "android_constant_name": "SCORE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_success",
    "android_constant_name": "SUCCESS",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_price",
    "android_constant_name": "PRICE",
    "Type": "float"
  },
  {
    "event_parameter_name": "af_content_type",
    "android_constant_name": "CONTENT_TYPE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_content_id",
    "android_constant_name": "CONTENT_ID",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_content_list",
    "android_constant_name": "CONTENT_LIST",
    "Type": "String[]"
  },
  {
    "event_parameter_name": "af_currency",
    "android_constant_name": "CURRENCY",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_quantity",
    "android_constant_name": "QUANTITY",
    "Type": "int"
  },
  {
    "event_parameter_name": "af_registration_method",
    "android_constant_name": "REGISTRATION_METHOD",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_payment_info_available",
    "android_constant_name": "PAYMENT_INFO_AVAILABLE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_max_rating_value",
    "android_constant_name": "MAX_RATING_VALUE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_rating_value",
    "android_constant_name": "RATING_VALUE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_search_string",
    "android_constant_name": "SEARCH_STRING",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_date_a",
    "android_constant_name": "DATE_A",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_date_b",
    "android_constant_name": "DATE_B",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_destination_a",
    "android_constant_name": "DESTINATION_A",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_destination_b",
    "android_constant_name": "DESTINATION_B",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_description",
    "android_constant_name": "DESCRIPTION",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_class",
    "android_constant_name": "CLASS",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_event_start",
    "android_constant_name": "EVENT_START",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_event_end",
    "android_constant_name": "EVENT_END",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_lat",
    "android_constant_name": "LAT",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_long",
    "android_constant_name": "LONG",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_customer_user_id",
    "android_constant_name": "CUSTOMER_USER_ID",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_validated",
    "android_constant_name": "VALIDATED",
    "Type": "boolean"
  },
  {
    "event_parameter_name": "af_revenue",
    "android_constant_name": "REVENUE",
    "Type": "float"
  },
  {
    "event_parameter_name": "af_projected_revenue",
    "android_constant_name": "PROJECTED_REVENUE",
    "Type": "float"
  },
  {
    "event_parameter_name": "af_receipt_id",
    "android_constant_name": "RECEIPT_ID",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_tutorial_id",
    "android_constant_name": "TUTORIAL_ID",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_virtual_currency_name",
    "android_constant_name": "VIRTUAL_CURRENCY_NAME",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_deep_link",
    "android_constant_name": "DEEP_LINK",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_old_version",
    "android_constant_name": "OLD_VERSION",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_new_version",
    "android_constant_name": "NEW_VERSION",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_review_text",
    "android_constant_name": "REVIEW_TEXT",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_coupon_code",
    "android_constant_name": "COUPON_CODE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_order_id",
    "android_constant_name": "ORDER_ID",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_param_1",
    "android_constant_name": "PARAM_1",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_param_2",
    "android_constant_name": "PARAM_2",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_param_3",
    "android_constant_name": "PARAM_3",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_param_4",
    "android_constant_name": "PARAM_4",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_param_5",
    "android_constant_name": "PARAM_5",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_param_6",
    "android_constant_name": "PARAM_6",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_param_7",
    "android_constant_name": "PARAM_7",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_param_8",
    "android_constant_name": "PARAM_8",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_param_9",
    "android_constant_name": "PARAM_9",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_param_10",
    "android_constant_name": "PARAM_10",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_departing_departure_date",
    "android_constant_name": "DEPARTING_DEPARTURE_DATE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_returning_departure_date",
    "android_constant_name": "RETURNING_DEPARTURE_DATE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_destination_list",
    "android_constant_name": "DESTINATION_LIST",
    "Type": "String[]"
  },
  {
    "event_parameter_name": "af_city",
    "android_constant_name": "CITY",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_region",
    "android_constant_name": "REGION",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_country",
    "android_constant_name": "COUNTRY",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_departing_arrival_date",
    "android_constant_name": "DEPARTING_ARRIVAL_DATE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_returning_arrival_date",
    "android_constant_name": "RETURNING_ARRIVAL_DATE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_suggested_destinations",
    "android_constant_name": "SUGGESTED_DESTINATIONS",
    "Type": "String[]"
  },
  {
    "event_parameter_name": "af_travel_start",
    "android_constant_name": "TRAVEL_START",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_travel_end",
    "android_constant_name": "TRAVEL_END",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_num_adults",
    "android_constant_name": "NUM_ADULTS",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_num_children",
    "android_constant_name": "NUM_CHILDREN",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_num_infants",
    "android_constant_name": "NUM_INFANTS",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_suggested_hotels",
    "android_constant_name": "SUGGESTED_HOTELS",
    "Type": "String[]"
  },
  {
    "event_parameter_name": "af_user_score",
    "android_constant_name": "USER_SCORE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_hotel_score",
    "android_constant_name": "HOTEL_SCORE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_purchase_currency",
    "android_constant_name": "PURCHASE_CURRENCY",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_preferred_neighborhoods",
    "android_constant_name": "PREFERRED_NEIGHBORHOODS",
    "Type": "String[]"
  },
  {
    "event_parameter_name": "af_preferred_num_stops",
    "android_constant_name": "PREFERRED_NUM_STOPS",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_adrev_ad_type",
    "android_constant_name": "AD_REVENUE_AD_TYPE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_adrev_network_name",
    "android_constant_name": "AD_REVENUE_NETWORK_NAME",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_adrev_placement_id",
    "android_constant_name": "AD_REVENUE_PLACEMENT_ID",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_adrev_ad_size",
    "android_constant_name": "AD_REVENUE_AD_SIZE",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_adrev_mediated_network_name",
    "android_constant_name": "AD_REVENUE_MEDIATED_NETWORK_NAME",
    "Type": "String"
  },
  {
    "event_parameter_name": "af_preferred_price_range",
    "android_constant_name": "PREFERRED_PRICE_RANGE",
    "Type": "String, int tuple formatted as (min,max)"
  },
  {
    "event_parameter_name": "af_preferred_star_ratings",
    "android_constant_name": "PREFERRED_STAR_RATINGS",
    "Type": "String, int tuple formatted as (min,max)"
  }
];

exports.androidEvents=[
  {
    "event_name": "af_level_achieved",
    "android_constant_name": "AFInAppEventType.LEVEL_ACHIEVED"
  },
  {
    "event_name": "af_add_payment_info",
    "android_constant_name": "AFInAppEventType.ADD_PAYMENT_INFO"
  },
  {
    "event_name": "af_add_to_cart",
    "android_constant_name": "AFInAppEventType.ADD_TO_CART"
  },
  {
    "event_name": "af_add_to_wishlist",
    "android_constant_name": "AFInAppEventType.ADD_TO_WISH_LIST"
  },
  {
    "event_name": "af_complete_registration",
    "android_constant_name": "AFInAppEventType.COMPLETE_REGISTRATION"
  },
  {
    "event_name": "af_tutorial_completion",
    "android_constant_name": "AFInAppEventType.TUTORIAL_COMPLETION"
  },
  {
    "event_name": "af_initiated_checkout",
    "android_constant_name": "AFInAppEventType.INITIATED_CHECKOUT"
  },
  {
    "event_name": "af_purchase",
    "android_constant_name": "AFInAppEventType.PURCHASE"
  },
  {
    "event_name": "af_rate",
    "android_constant_name": "AFInAppEventType.RATE"
  },
  {
    "event_name": "af_search",
    "android_constant_name": "AFInAppEventType.SEARCH"
  },
  {
    "event_name": "af_spent_credits",
    "android_constant_name": "AFInAppEventType.SPENT_CREDITS"
  },
  {
    "event_name": "af_achievement_unlocked",
    "android_constant_name": "AFInAppEventType.ACHIEVEMENT_UNLOCKED"
  },
  {
    "event_name": "af_content_view",
    "android_constant_name": "AFInAppEventType.CONTENT_VIEW"
  },
  {
    "event_name": "af_list_view",
    "android_constant_name": "AFInAppEventType.LIST_VIEW"
  },
  {
    "event_name": "af_travel_booking",
    "android_constant_name": "AFInAppEventType.TRAVEL_BOOKING"
  },
  {
    "event_name": "af_share",
    "android_constant_name": "AFInAppEventType.SHARE"
  },
  {
    "event_name": "af_invite",
    "android_constant_name": "AFInAppEventType.INVITE"
  },
  {
    "event_name": "af_login",
    "android_constant_name": "AFInAppEventType.LOGIN"
  },
  {
    "event_name": "af_re_engage",
    "android_constant_name": "AFInAppEventType.RE_ENGAGE"
  },
  {
    "event_name": "af_update",
    "android_constant_name": "AFInAppEventType.UPDATE"
  },
  {
    "event_name": "af_location_coordinates",
    "android_constant_name": "AFInAppEventType.LOCATION_COORDINATES"
  },
  {
    "event_name": "af_customer_segment",
    "android_constant_name": "AFInAppEventType.CUSTOMER_SEGMENT"
  },
  {
    "event_name": "af_subscribe",
    "android_constant_name": "AFInAppEventType.SUBSCRIBE"
  },
  {
    "event_name": "af_start_trial",
    "android_constant_name": "AFInAppEventType.START_TRIAL"
  },
  {
    "event_name": "af_ad_click",
    "android_constant_name": "AFInAppEventType.AD_CLICK"
  },
  {
    "event_name": "af_ad_view",
    "android_constant_name": "AFInAppEventType.AD_VIEW"
  },
  {
    "event_name": "af_opened_from_push_notification",
    "android_constant_name": "AFInAppEventType.OPENED_FROM_PUSH_NOTIFICATION"
  }
]