/* eslint-disable */

import { EvgenEventsObject } from "../../inapp/IEvgen";

export interface IProgress {
  id: string;
  text: string;
  order: number;
  isListener?: boolean;
  startTime?: Date;
  updateTime?: Date;
  duration?: number;
  pageChosenOption?: object;
  pageChosenOptionType?: string;
}

export enum OSType {
  Android = "android",
  iOS = "ios",
  unselected = "",
}

export enum SDKTestType {
  Automated = "testconsole",
  Manual = "manual",
}

export interface ISessionProps {
  sessionId: string;
  startTime: Date;
  updateTime: Date;
  sessionDuration?: number;
  os: OSType;
  appID?: string;
  oneLinkURL?: string;
  uriScheme?: string;
  progress: IProgress[];
  currentPage: number;
  pageChosenOption?: any;
  pageChosenOptionType?: string;
  path?: IntegrationPathType;
  isDirectDone?: boolean;
  isDeferredDone?: boolean;
  installUID?: string;
  deviceId?: string;
  completionStatus?: boolean;
  completionResult?: string;
  sessionStatus: SessionStatus;
  sdkTestType?: SDKTestType;
  lang?: string;
  eventName?: string;
  eventParams?: string[];
  eventName_received?: string;
  eventParams_received?: string;
  retriesCounter?: number;
  deeplinkType?: string;
  evgenEventsObject?: EvgenEventsObject;
  isEvgenTesting?: boolean;
  tabClick?: boolean;
  migrateClick?: number;
}

export enum IntegrationPathType {
  StartSDK = "startsdk",
  InApp = "inapp",
  DeepLink = "deeplink",
  unset = "",
}

export enum SessionStatus {
  Incomplete = "incomplete",
  ImplementedNotTested = "implemented_not_tested",
  IncompleteTesting = "incomplete_testing",
  Failed = "failed",
  Success = "success",
}
